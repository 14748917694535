/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useContext } from "react";
import {
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogContent,
  Dialog,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { Badge } from "components/ui/badge";
import { ScrollArea } from "components/ui/scroll-area";
import { MagnifyingGlassIcon, Cross1Icon, CheckIcon } from "@radix-ui/react-icons";
import { LoaderSpinner, AppLoader } from "components/LoaderSpinner";
import { Button } from "components/ui/button";
import { cn } from "lib/utils";
import { SubOrgContext } from "context/SubOrganizationContext";
import { assistantInstanceService } from "api";
import { Context } from "context/GlobalState";
import { useParams } from "react-router-dom";
import { projectService } from "api/services/BEX/project.service";

const UpdateCollectionDialog = ({ show, setShow, collectionId }) => {

    const [btnLoading, setBtnLoading] = useState(false);
    const [assistants, setAssistants] = useState([]);
    const [loadingAssistants, setLoadingAssistants] = useState(true);
    const [selectedAssistant, setSelectedAssistant] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const { project, actions } = useContext(SubOrgContext)
    const { addNewNotifcation } = useContext(Context)
    const { oragID, subOragID } = useParams()

    function filterArray(array1, array2) {
        const assistantIdsInArray2 = new Set(
            array2.flatMap(collection => collection.assistants.map(assistant => assistant._id))
        );
        return array1.filter(item => assistantIdsInArray2.has(item._id));
    }

    const getData = async () => {
        if (!show) return;
        try {
            const assistantInstances = await assistantInstanceService.getSubOrgAssistantInstance(oragID, subOragID)
            setAssistants(assistantInstances.data);
            setSelectedAssistant(filterArray(assistantInstances.data, project.collections));
        } catch (error) {
            console.log("error: ", error);
        } finally {
            setLoadingAssistants(false);
        }
    };

    useEffect(() => {
        getData();
    }, [show]);

    const handleUpdateCollection = () => {
        setBtnLoading(true);

        const updatedCollectionObj = {
            organizationId: oragID,
            subOrganizationId: subOragID,
            projectId: project._id,
            collectionId: collectionId,
            assistants: selectedAssistant.map(item => (item._id)),
        }
        projectService.updateCollection(updatedCollectionObj)
        .then((res) => {
            setShow(false);
            actions({type: 'SET_PROJECT', payload: res.data})
            addNewNotifcation("Sub-Organization updated successfully", "success");
        })
        .catch((error) => {
            console.log("error: ", error);
            if (error?.response?.status === 404) {
                addNewNotifcation("Collection not found", "danger");
            } else {
                addNewNotifcation("Something went wrong", "danger");
            }
        })
        .finally(() => {
            setBtnLoading(false);
        });
    };

    const handleSelectAssistant = (assistant) => {
        if (!selectedAssistant.some((m) => m._id === assistant._id)) {
            setSelectedAssistant([...selectedAssistant, assistant]);
        }
    };

    const handleRemoveAssistant = (assistant) => {
        setSelectedAssistant(selectedAssistant.filter((m) => m._id !== assistant._id));
    };

    const filteredAssistants = assistants.filter((assistant) => assistant.title.toLowerCase().includes(searchQuery.toLowerCase())    );

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="max-h-[650px] overflow-scroll w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">
                        Update Collection
                    </DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">
                        Modify the assistants linked to this collection
                    </DialogDescription>
                </DialogHeader>
                
                <div className="space-y-6">
                <div className="flex">
                    <ScrollArea className="rounded-md min-h-0 max-h-[80px]">
                        <div className="flex flex-wrap gap-2">
                            {selectedAssistant.map((assistant) => (
                            <Badge
                                key={assistant._id}
                                variant="secondary"
                                className="p-2 text-sm"
                            >
                                {assistant.title}
                                <Cross1Icon
                                className="ml-2 cursor-pointer"
                                onClick={() => handleRemoveAssistant(assistant)}
                                />
                            </Badge>
                            ))}
                        </div>
                    </ScrollArea>
                </div>
                <div className="relative space-y-3">
                    <Label htmlFor="assistant-search" className="text-md">
                        Assistants Selection
                    </Label>
                    <MagnifyingGlassIcon className="absolute top-7 left-3 h-6 w-6 text-muted-foreground" />
                    <Input
                    id="assistant-search"
                    placeholder="Search assistants..."
                    type="search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full pl-12 pr-5 py-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                    />
                    <ScrollArea className="border-solid rounded-md h-56">
                    <div className="flex flex-col p-3 space-y-3">
                        {loadingAssistants ? (
                        <AppLoader size={60} />
                        ) : filteredAssistants.length === 0 ? (
                        <div className="text-center text-muted-foreground">
                            No assistants found
                        </div>
                        ) : (
                        filteredAssistants.map((assistant) => (
                            <div
                            key={assistant._id}
                            className="flex items-center space-x-3 cursor-pointer"
                            onClick={() => handleSelectAssistant(assistant)}
                            >
                            <div>
                                <div className="font-medium text-md">{assistant.title}</div>
                            </div>
                            <CheckIcon
                                className={cn(
                                "ml-auto h-6 w-6",
                                selectedAssistant.some((m) => m._id === assistant._id)
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                            />
                            </div>
                        ))
                        )}
                    </div>
                    </ScrollArea>
                </div>
                </div>

                <DialogFooter className="flex justify-end space-x-5 mt-6">
                <Button
                    variant="outline"
                    onClick={() => setShow(false)}
                    className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground"
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleUpdateCollection}
                    className="px-5 py-3 text-md"
                >
                    {btnLoading ? <LoaderSpinner /> : "Update Collection"}
                </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateCollectionDialog;
