import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { httpRequestO } from "../../../utils/httpsRequest";
import { Context } from "../../../context/GlobalState";
import { useParams } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import { Card, CardContent } from "../../../components/ui/card";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "../../../components/ui/table"
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';

const MatchResult = ({ selectedCV, selectedJD, selectedFolder,orgID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [matchResults, setMatchResults] = useState([]);
  const [messages, setMessages] = useState([]);
  const [buttonText, setButtonText] = useState("Match");
  const inputRef = useRef(null);
  const messageListRef = useRef(null);
  const { addNewNotifcation, user } = useContext(Context);
  const {subOragID, oragID} = useParams();
  const [matchScores, setMatchScores] = useState([]);
  const [llmResponse, setLlmResponse] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);


  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [isLoading]);

  useEffect(() => {
    let timer1, timer2, timer3;

    if (isLoading && !matchResults.length) {
      timer1 = setTimeout(() => setButtonText("Loading..."), 0);
      timer2 = setTimeout(() => setButtonText("Scanning CVs..."), 10000);
      timer3 = setTimeout(() => setButtonText("Checking qualifications..."), 20000);
      setTimeout(() => setButtonText("Matching candidates..."), 30000);
    }

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [isLoading, matchResults]);

  const handleQueryJD = async () => {
    const jdPromiseArr = [];
    let fromRag = "";
    console.log("SELECTED JD:",selectedJD)
    try {
      if (selectedJD.length === 0){
        console.log("NO SELECTED")

          const formData = new FormData();
          formData.append('organization_id', oragID);
          formData.append('sub_organization_id', process.env.REACT_APP_DEMO_MODE);
          formData.append("pre_filter", JSON.stringify({folder:"JD_upload",uploader:user.email}));
          formData.append('k', 15);
          formData.append('query', "Experience and Skills");

          const queryResponse = await httpRequestO.post('/projecto/api/v1/embedding_api_service/query_data/', formData);
          console.log(queryResponse);
          for (let pageContent of queryResponse.data.data.documents) {
            fromRag += `[Document]\n`;
            fromRag += `Document Name: ${pageContent.metadata.filename}\n`;
            fromRag += `${pageContent.page_content}\n`;
            fromRag += `[End]\n`;
          }
        
        return fromRag;
      } 
      // BELOW IS NEEDED
      else if (selectedJD.length > 0){
        // console.log("SELECTED JD: ", selectedJD);
        for (const [index, document] of selectedJD.entries()) {
          // console.log("DOC ID", document.documentRecord._id)
          console.log(document);
          const formData = new FormData();
          formData.append('organization_id', document.documentRecord.organization_id ? document.documentRecord.organization_id : orgID);
          formData.append('sub_organization_id', document.documentRecord.sub_organization_id ? document.documentRecord.sub_organization_id : process.env.REACT_APP_DEMO_MODE);
          formData.append('document_id', document.documentRecord._id);
          formData.append('k', 15);
          formData.append('query', "Experience and Skills");

          jdPromiseArr.push(httpRequestO.post(`/projecto/api/v1/embedding_api_service/query_data/`, formData));
        }

        const jdResponses = await Promise.all(jdPromiseArr);
        for (let documentResult of jdResponses) {
          for (let pageContent of documentResult.data.data.documents) {
            fromRag += `[Document]\n`;
            fromRag += `Document Name: ${pageContent.metadata.filename}\n`;
            fromRag += `${pageContent.page_content}\n`;
            fromRag += `[End]\n`;
          }
        }
        return fromRag;
      }
      
    } catch (error) {
      console.log('Error in handleQueryJD:', error);
    }
  };

  const handleQueryCV = async () => {
    const cvPromiseArr = [];
    let fromRag = "";
    try {
      //BELOW IS NEEDED
      if (selectedCV.length > 0) {
        console.log("SELECTED CV: ", selectedCV);
        for (const document of selectedCV) {
          const formData = new FormData();
          formData.append('organization_id', document.documentRecord.organization_id ? document.documentRecord.organization_id : orgID);
          formData.append('sub_organization_id', document.documentRecord.sub_organization_id ? document.documentRecord.sub_organization_id : process.env.REACT_APP_DEMO_MODE);
          formData.append('document_id', document.documentRecord._id);
          formData.append('k', 15);
          formData.append('query', "Experience and Skills");

          cvPromiseArr.push(httpRequestO.post('/projecto/api/v1/embedding_api_service/query_data/', formData));
        }
        const cvResponses = await Promise.all(cvPromiseArr);
        for (let documentResult of cvResponses) {
          for (let pageContent of documentResult.data.data.documents) {
            fromRag += `[Document]\n`;
            fromRag += `Document Name: ${pageContent.metadata.filename}\n`;
            fromRag += `${pageContent.page_content}\n`;
            fromRag += `[End]\n`;
          }
        }

        
        return fromRag;

      }
      
      
      else if (selectedCV.length< 1){
        console.log("SELECTED FOLDER", selectedFolder);
        for (const [index, document] of selectedCV.entries()) {
          const formData = new FormData();
          formData.append('organization_id', oragID);
          formData.append('sub_organization_id', process.env.REACT_APP_DEMO_MODE);
          formData.append('pre_filter', JSON.stringify({folder:selectedFolder}));
          formData.append('k', 15);
          formData.append('query', "Experience and Skills");

          cvPromiseArr.push(httpRequestO.post(`/projecto/api/v1/embedding_api_service/query_data/`, formData));
        }

        const jdResponses = await Promise.all(cvPromiseArr);
        for (let documentResult of jdResponses) {
          for (let pageContent of documentResult.data.data.documents) {
            fromRag += `[Document]\n`;
            fromRag += `Document Name: ${selectedCV.find(doc => doc._id === pageContent.documentRecord.metadata.document_id).name}\n`;
            fromRag += `${pageContent.page_content}\n`;
            fromRag += `[End]\n`;
          }
        }
        return fromRag;
      }
    
    } catch (error) {
      console.log('Error in handleQueryCV:', error);
    }
  };

  const cleanJDText = async (textData) => {
  const cleaningPrompt = `Input:
You are given a job description (JD). Your task is to preprocess and refine the text to standardize its format and enhance its clarity. Please follow these steps:

1. Clean and Remove any special characters (#, @, *,---), redundant spaces, and irrelevant content to ensure the text is focused and concise.
2. Ensure consistent formatting by converting the text to lowercase, eliminating jargon, and streamlining any inconsistencies.
3. Replace alternative terms, synonyms, or colloquial expressions with their standardized equivalents. For example, convert informal 
terms like "software guru" to "software engineer.". Make the JD text to be straightforward and clear.
4. Enrich the description by adding relevant skills or information that align with the job title and industry standards. For example,
if the JD lacks specific required skills, infer and include these based on the context.
5. Modify the text to ensure it conveys a formal and professional tone throughout.

Do not add any unnecessary text before and after the modified JD. Return only the entire cleaned Job Description. 

${textData}

Output:
`;
  
    const cleanRequestData = {
      prompt: cleaningPrompt,
      modelId: "mistralai/mistral-large",
      parameters: {
        decoding_method: "greedy",
        max_new_tokens: 1000,
        min_new_tokens: 0,
        repetition_penalty: 1
      },
      query: ""
    };
  
    console.log("Cleaning Request Data:", cleanRequestData);
  
    try {
      const llmResp = await httpRequestO.post(
        "/projecto/api/v1/model/foundationModel/experiment?mode=freeMode",
        cleanRequestData
      );
  
      let cleanedJDText = llmResp.data.prediction;
      console.log("Cleaned JD:", cleanedJDText);
  
      return cleanedJDText.trim();
    } catch (error) {
      console.error("Error in cleanJDText:", error);
      return textData;  
    }
  };

//   const preprocessCVText = async (cvText) => {
//     const preprocessCVPrompt = `Input:
// You are provided with a list of candidate's CV. Your task is to preprocess and clean the CV to ensure that the text is consistent, clear, and ready for further analysis. Please follow these steps:

// 1. Remove any unwanted special characters, excessive spaces, and non-standard symbols that do not contribute to the content.
// 2. Convert the text to lowercase to ensure uniformity, and replace any informal terms or abbreviations with their standard equivalents. For example, replace "dev" with "developer."
// 3. Remove any sections or content that are not relevant to the job application, such as personal hobbies or outdated skills.
// 4. Ensure that different sections of the CV (e.g., experience, education, skills) are clearly structured and labeled.
// 5. Extract and organize important details such as work experience, skills, education, job titles, and location, while removing any redundant or unnecessary content.

// Return the cleaned CV text in JSON object that is ready for further processing.

// "candidate_name": The name of the applicant.
// "previous_job": The most recent or relevant job title of the candidate.
// "skills": List of skills the candidate possesses, or any special qualifications.
// "experience": Years of Related Experience in the field and Relevant Experiences of the applicant.
// "education": Highest degree attained and relevant educational background.
// "location": The current country location of the candidate and their preference or ability for onsite, hybrid, or remote work.
// "bonus_skills": Any additional skills or qualifications that may be considered an added advantage but are not mandatory.
// "certifications": Relevant certifications or licenses that might be required or advantageous for the job.

// List of CVs: ${cvText}

// Output:
// `;
    
    //   const preprocessRequestData = {
    //     prompt: preprocessCVPrompt,
    //     modelId: "mistralai/mistral-large",
    //     parameters: {
    //       decoding_method: "greedy",
    //       max_new_tokens:3000,
    //       min_new_tokens: 0,
    //       repetition_penalty: 1
    //     },
    //     query: ""
    //   };
    
    //   console.log("Extract CV Request Data:", preprocessRequestData);
    
    //   try {
    //     const llmResp = await httpRequestO.post(
    //       "/projecto/api/v1/model/foundationModel/experiment?mode=freeMode",
    //       preprocessRequestData
    //     );
    
    //     let extractedCV = llmResp.data.prediction;
    //     console.log("LLM Extracted CVs:", extractedCV);
    
    //     extractedCV = extractedCV
    //       .replace(/```json/g, '')  
    //       .replace(/```/g, '')     
    //       .trim();                 

    //     if (!extractedCV.startsWith("[")) {
    //       extractedCV = `[${extractedCV}]`;
    //     }

    //     let parsedCVDetails = [];
    //     try {
    //       parsedCVDetails = JSON.parse(extractedCV);
    //     } catch (error) {
    //       console.error("Error parsing extracted CV details:", error);
    //       return null;
    //     }

    //     return parsedCVDetails;
    //   } catch (error) {
    //     console.error("Error in extracted CV:", error);
    //     return cvText;  
    //   }
    // };

//       const extractJdInfo = async (cleanedJDText) => {
//         const extractionPrompt = `Input:

//   You are given a job description (JD) and your task is to extract and evaluate the following information from the document:

//  Provide the result as a JSON object with the following fields:

//   "hiring_position": Title of the job they are applying for.
//   "job_description": The details on the responsibilities of the applicant.
//   "skills": Required skills and qualifications from the applicant.
//   "experience": How many Years of Related Experience in the field and Relevant Experiences of the applicant.
//   "education": Required degree or education of the applicant.
//   "location": Required Location or type of work of the applicant (onsite, hybrid or remote).
//   "bonus_skills": Extract if there are any bonus skills that can be an added advantage.
//   "certifications": Any certifications or licenses the candidate holds that are required or advantageous for the job.

//   ${cleanedJDText}

//   Output:
//   `;
    
//           const extractJDRequestData = {
//             prompt: extractionPrompt,
//             modelId: "mistralai/mistral-large",
//             parameters: {
//               decoding_method: "greedy",
//               max_new_tokens: 1000,
//               min_new_tokens: 0,
//               repetition_penalty: 1
//             },
//             query: ""
//           };
    
//           console.log("Extraction Request Data:", extractJDRequestData);
    
//           try {
//             const llmResp = await httpRequestO.post(
//               "/projecto/api/v1/model/foundationModel/experiment?mode=freeMode",
//               extractJDRequestData
//             );
    
//             let extractedJD = llmResp.data.prediction;
//             //console.log("Extracted JD:", extractedJD);
    
//               extractedJD = extractedJD
//               .replace(/```json/g, '')  
//               .replace(/```/g, '')     
//               .trim();                 

//             if (!extractedJD.startsWith("[")) {
//               extractedJD = `[${extractedJD}]`;
//             }

//             let parsedJDDetails = [];
//             try {
//               parsedJDDetails = JSON.parse(extractedJD);
//             } catch (error) {
//               console.error("Error parsing extracted JD details:", error);
//               return null;
//             }

//             return parsedJDDetails;
//           } catch (error) {
//             console.error("Error in cleanJDText:", error);
//             return cleanedJDText;  
//           }
//       };
  
  const getMatchScoreAndSendMessage = async () => {
    try {
        setIsLoading(true);
        setIsDataReady(false);

        const [relatedPassagesJD, relatedPassagesCV] = await Promise.all([
          handleQueryJD(),
          handleQueryCV()
        ]);

        const cleanedJD = await cleanJDText(relatedPassagesJD);

        console.log("Cleaned Job Description:", cleanedJD);
        //console.log("Preprocessed CVs:", preprocessedCVs);
    
        // const extractedJdDetails = await extractJdInfo(cleanedJD);
        // console.log("Extracted Details on Job Description:", extractedJdDetails);

      //   const folderNames = Array.from(
      //     new Set(selectedCV.map(document => document.documentRecord?.folder || "CV_DefaultFolder"))
      // );
      
      // const preFilter = folderNames.length === 1 
      //     ? { folder: folderNames[0] }
      //     : { folder: { $in: folderNames } };

      //     const filterWithAssistant = {
      //       ...preFilter,
      //       assistant: "hr-assistant"
      //   };

      //   const formData = new FormData();
      //   formData.append('organization_id', process.env.REACT_APP_COMMON_ORGANIZATION_ID);
      //   formData.append('sub_organization_id', process.env.REACT_APP_COMMON_SUB_ORGANIZATION_ID);
      //   formData.append('k', 9);
      //   formData.append('query', relatedPassagesJD);
      //   formData.append('pre_filter', JSON.stringify(preFilter));

      //   const formDataEntries = Array.from(formData.entries());
      //   console.log("Requestbody passed to API:");
      //   formDataEntries.forEach(([key, value]) => {
      //       console.log(`${key}: ${value}`);
      //   });

      //   const response = await httpRequestO.post('/projecto/api/v1/embedding_api_service/query_data/', formData);
      
      //   const newMatchResults = response.data.data.documents.map(doc => ({
      //       matchScore: doc.score,
      //       //candidateName: doc.metadata?.name || "Unknown",
      //   }));

      //   setMatchScores(newMatchResults);
      //   console.log("match scores", matchScores);

let fullPrompt = `Input:
You are an HR Recruiter andou are provided with this job description: ${cleanedJD ? cleanedJD : ""}, and a list of all the applicants' CV: ${relatedPassagesCV ? relatedPassagesCV : ""}. 
Your task is to evaluate closely who among the candidates' qualifications match the job description. Each criterion has a specific weight in determining the overall similarity score. 
Calculate the match percentage between the job description and the CV based on the following criteria and their respective weights:

Experience (30%): Compare the candidate's relevant experience with the job requirements.
Skills (25%): Match the top 5 skills found in the CV that are listed or related in the job description requirements, if none, make it null.
Education (20%): Assess how the candidate’s educational background aligns with the job qualifications.
Job Title (15%): Compare past job titles and responsibilities with those in the job description.
Location (5%): Check if the candidate’s location and availability fit the job requirements.

Provide the result as a JSON object with the following fields:

"candidate_name": Name of the candidate.
"hiring_position": Title of the job they are applying.
"remarks": Provide if applicant is qualified when most of the job requirements are met or not qualified if applicant has not met the requirements or when overqualified.
"match_percentage": Calculate the accurate and Overall match rating percentage of the applicant based on the criteria and weightage. Calculate based on how close it was to the job requirements.
There are some applicants that can get low percentage and If the CV does not match any requirements and is not related to the Job Description, make the match percentage to 0.
"criteria": A JSON object detailing each criterion:
"skills": Top 5 Relevant Skills of applicant that ONLY matched with Job Description's requirements.
"experience": How many Years of Related Experience in the field and Relevant Experiences of applicant that ONLY matched with Job Description.
"education": Highest attainment of applicant 
"job_title": Previous job of applicant
"location": Location of applicant
"certifications": Any certifications or licenses that is relevant to the requirements of the job description.
"reason_for_remarks": Justify the insights and reason of the match percentage calculated and given to the applicant.

Output:
`;

        const requestData = {
            prompt: fullPrompt,
            modelId: "mistralai/mistral-large",
            parameters: {
                decoding_method: "greedy",
                max_new_tokens: 2000,
                min_new_tokens: 0,
                repetition_penalty: 1,
                stop_sequences: ["\n\n\n"]
            },
            query: ""
        };

        console.log("Request Data:", requestData);
        const llmResp = await httpRequestO.post(
            "/projecto/api/v1/model/foundationModel/experiment?mode=freeMode",
            requestData
        );

        let responseText = llmResp.data.prediction;
        console.log("LLM response:", responseText);

        responseText = responseText
            .replace(/```json/g, '')       
            .replace(/```/g, '')     
            .replace(/\n\n\n/g, '')  
            .trim();

    
            if (!responseText.startsWith("[")) {
              responseText = `[${responseText}]`;
          }

  
        responseText = responseText.replace(/}\s*{/g, '},{');
      
        let jsonResponse = [];
        try {
            jsonResponse = JSON.parse(responseText);
            //setLlmResponse(jsonResponse);
            setMatchResults(jsonResponse); 
            setIsDataReady(true);
            console.log("Cleaned response::", jsonResponse);
            
            console.log("Match results:", matchResults);
        } catch (parseError) {
            console.error("Error parsing LLM response:", parseError);
            console.error("Problematic JSON:", responseText);
        }

    } catch (error) {
        console.error("Error in getMatchScoreAndSendMessage:", error);
    } finally {
        setIsLoading(false);
    }
  };

  return (
    <>
        <div className="flex items-center justify-between">
            <div className="space-y-1">
                <h2 className="text-2xl font-bold mt-6">Match Candidates to Jobs</h2>
                <p className="text-muted-foreground">Click the button to find the best matches.</p>
            </div>
            <Button
                className="bg-primary text-primary-foreground hover:bg-primary/90"
                onClick={() => {
                    setIsLoading(true);
                    getMatchScoreAndSendMessage();
                }}
                disabled={isLoading || selectedJD.length === 0 || selectedCV.length === 0}
            >
                {isLoading ? (
                    <div className="flex items-center justify-center">
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-primary-foreground" />
                        <span className="ml-2">{buttonText}</span>
                    </div>
                ) : (
                    "Match"
                )}
            </Button>
        </div>
        <Card className="mt-4 bg-card text-card-foreground shadow-sm">
            <CardContent>
                <div className="overflow-x-auto w-full">
                    <Table className="w-full min-w-[1800px]">
                        <TableHeader>
                            <TableRow>
                                <TableHead className="w-40 text-md font-semibold py-3 px-4">Candidate</TableHead>
                                <TableHead className="w-40 text-md font-semibold py-3 px-4">Previous Job</TableHead>
                                <TableHead className="w-[150px] text-md font-semibold py-3 px-4">Match Score</TableHead>
                                <TableHead className="w-[230px] text-md font-semibold py-3 px-4">Remarks</TableHead>
                                <TableHead className="w-[700px] text-md font-semibold py-3 px-4">Experiences</TableHead>
                                <TableHead className="w-[500px] text-md font-semibold py-3 px-4">Skills</TableHead>
                                <TableHead className="w-[500px] text-md font-semibold py-3 px-4">Education</TableHead>
                                <TableHead className="w-[230px] text-md font-semibold py-3 px-4">Location</TableHead>
                                <TableHead className="w-[1000px] text-md font-semibold py-3 px-4">Insights</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {matchResults.length > 0 ? (
                                matchResults
                                    .sort((a, b) => (b.match_percentage || 0) - (a.match_percentage || 0))
                                    .map((result, index) => {
                                        const criteria = result.criteria || {};
                                        const remarks = result.remarks || "Not known";
                                        const isQualified = remarks.toLowerCase().includes("not qualified");
                                        const rowColor = isQualified ? 'bg-red-100' : 'bg-green-100';
                                        const icon = isQualified ? <CrossCircledIcon className="w-5 h-5 text-red-500" /> : <CheckCircledIcon className="w-5 h-5 text-green-500" />;

                                        return (
                                            <TableRow key={index} className={rowColor}>
                                                <TableCell className="font-medium">
                                                    <div className="flex items-center gap-2">
                                                        <div>{result.candidate_name || "Not Known"}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>{criteria.job_title || "No previous job"}</TableCell>
                                                <TableCell>
                                                    <div className="flex items-center gap-2">
                                                        <div>{`${result.match_percentage}%`}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="flex items-center gap-2">
                                                        <div>{remarks}</div>
                                                        <div>{icon}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="flex items-center gap-2">
                                                        <div>{criteria.experience || "No relevant experience with this job position"}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="flex items-center gap-2">
                                                        <div>{Array.isArray(criteria.skills) && criteria.skills.length > 0
                                                            ? criteria.skills.join(', ')
                                                            : "No relevant skills"}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="flex items-center gap-2">
                                                        <div>{criteria.education || "Not known"}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="flex items-center gap-2">
                                                        <div>{criteria.location || "Not indicated"}</div>
                                                    </div>
                                                </TableCell>
                                                
                                                <TableCell>
                                                    <div className="flex items-center gap-2">
                                                        <div>{criteria.reason_for_remarks || result.reason_for_remarks}</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={9} className="text-center py-8 text-muted-foreground mt-6">
                                        No results available. Please select job descriptions and candidate CVs to find matches.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </CardContent>
        </Card>
    </>
);




};

export default MatchResult;