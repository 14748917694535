import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import { Context } from "../context/GlobalState";
import { AppLoader, LoaderContainerDiv } from "../components/LoaderSpinner";
import { SubOrgContext } from "context/SubOrganizationContext";
import { WorkflowRequired } from "pages/Dashboard/AssistantsDashboard";
import SideMenuWrapper from "./SideMenuWrapper";

export const ProtectedSubOrgRoute = () => {
    const { isAuth, user, addNewNotifcation } = useContext(Context);
    const {
        selectedSubOrganization,
        actions,
        loading,
        workflow,
        subOrgMenu,
    } = useContext(SubOrgContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { subOragID, oragID } = useParams();
    const [protectLoading, setProtectLoading] = useState(true);

    useEffect(() => {
        if (!isAuth) {
            sessionStorage.setItem("redirect-url", pathname);
            window.location = "/auth/login";
        } else {
            console.log("selectedSubOrganization: ", selectedSubOrganization);
            if (selectedSubOrganization._id !== subOragID) {
                // check if user is a member of the subOragID
                const subOrganizationObj = user.subOrganizations?.find(
                    (item) => item._id === subOragID
                );
                if (subOrganizationObj) {
                    actions({ type: "SET_SELECTED_SUB_ORGANIZATION", payload: subOrganizationObj });
                } else {
                    navigate(`/organizations/${oragID}/sub`);
                    addNewNotifcation(
                        "You are not a member of this organization",
                        "warning"
                    );
                }
            }
        }

        setProtectLoading(false);
    }, [isAuth, selectedSubOrganization, subOragID, user, actions, pathname, navigate, oragID, addNewNotifcation]);

    if (protectLoading) {
        return (
            <LoaderContainerDiv>
                <AppLoader size={50} />
            </LoaderContainerDiv>
        );
    }

    // check if workflow exists in sub organization or display WorkflowRequired component
    return loading ? (
        <AppLoader size={30} />
    ) : workflow ? (
        <SideMenuWrapper section={subOrgMenu}>
            <Outlet />
        </SideMenuWrapper>
    ) : (
        <WorkflowRequired />
    );
};
