import React, { useState, useRef } from 'react'
import { CheckIcon, CopyIcon } from 'lucide-react'
import { Button } from 'components/ui/button'

export default function CodeDisplay({ code }) {
    const [isCopied, setIsCopied] = useState(false)
    const codeRef = useRef(null)

    const copyToClipboard = async () => {
        if (codeRef.current) {
        try {
            await navigator.clipboard.writeText(codeRef.current.textContent || '')
            setIsCopied(true)
            setTimeout(() => setIsCopied(false), 2000)
        } catch (err) {
            console.error('Failed to copy text: ', err)
        }
        }
    }

    return (
        <div className="relative">
            <pre
                ref={codeRef}
                className="bg-gray-100 p-4 rounded-lg overflow-x-auto text-sm w-full whitespace-pre-wrap"
            >
                <code className="language-javascript">{code}</code>
            </pre>
            <Button
                className="absolute top-2 right-2"
                size="sm"
                variant="outline"
                onClick={copyToClipboard}
                aria-label={isCopied ? "Copied" : "Copy to clipboard"}
            >
                {isCopied ? (
                    <CheckIcon className="h-4 w-4 text-green-500" />
                ) : (
                    <CopyIcon className="h-4 w-4" />
                )}
            </Button>
        </div>
    )
}