import React, { useCallback, useState } from "react"
import { Check, ChevronsUpDown } from "lucide-react"
import { cn } from "lib/utils"
import { Button } from "components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover"

export default function MultiSelectComponent({ items, selectedItems, onItemsSelect, placeholder="Item...", disabled=false, className }) {
    const [open, setOpen] = useState(false)

    const handleSelect = useCallback((currentValue) => {
        const updatedSelection = selectedItems.includes(currentValue)
            ? selectedItems.filter((value) => value !== currentValue)
            : [...selectedItems, currentValue]
        onItemsSelect(updatedSelection)
    },[selectedItems, onItemsSelect])

    return (
        <Popover disabled={disabled} open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={cn("w-[300px] justify-between", className)}
                >
                    {selectedItems.length > 0
                    ? `${selectedItems.length} ${placeholder}${
                        selectedItems.length > 1 ? "s" : ""
                        } selected`
                    : `Select ${placeholder}s...`}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className={cn("w-[300px] p-0", className)}>
                <Command>
                    <CommandInput placeholder={`Search ${placeholder}...`} />
                    <CommandEmpty>No {placeholder} found.</CommandEmpty>
                    <CommandGroup>
                        {items.map((item) => (
                            <CommandItem
                            key={item.value}
                            onSelect={() => handleSelect(item.value)}
                            >
                                <Check
                                    className={cn(
                                    "mr-2 h-4 w-4",
                                    selectedItems.includes(item.value)
                                        ? "opacity-100"
                                        : "opacity-0"
                                    )}
                                />
                                {item.label}
                            </CommandItem>
                        ))}
                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    )
}