import React, { createContext, useState, useContext } from 'react';
import {Context} from "context/GlobalState";

export const SandboxContext = createContext({});

export const SandboxProvider = ({ children }) => {

  const {addNewNotifcation, user, selectedSubOrganization} = useContext(Context);

  const [editorCode, setEditorCode] = useState("# Write your code here");
  const [language, setLanguage] = useState('python');
  const [output, setOutput] = useState('');
  const [inputMessage, setInputMessage] = useState('');
  const [sandBoxConvo, setSandboxConvo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);

 

  const value={
    addNewNotifcation, user, selectedSubOrganization,
    editorCode, setEditorCode,
    language, setLanguage,
    output, setOutput,
    inputMessage, setInputMessage,
    sandBoxConvo, setSandboxConvo,
    loading, setLoading,
    isExecuting, setIsExecuting,
  }

  return (
      <SandboxContext.Provider value={value}>
          {children}
      </SandboxContext.Provider>
  );
};