import SpeechToTextDashboard from './SpeechToText';
import EmailExtractionDashboard, { ActiveAssistant } from './EmailExtraction';
import LegalContractDashboard from './LegalContract';
import PromptingAndRagTrainingDashboard from './PromptingAndRagTraining';
import SmartContractDashboard from './SmartContract';
import TextToSpeechDashboard from './TextToSpeech';
import WebEngagement from './WebEngagement';
import HrAssistant from './HrAssistant';

export const assistantComponents = {
  '667538b01444181354ac98eb': EmailExtractionDashboard,
  '6675393fa13370ac974a2e90': SmartContractDashboard,
  '6675395ea13370ac974a2e93': LegalContractDashboard,
  '66753975a13370ac974a2e96': SpeechToTextDashboard,
  '6675398ba13370ac974a2e99': TextToSpeechDashboard,
  '667539a5a13370ac974a2e9c': PromptingAndRagTrainingDashboard,
  '66864d52e79314bdffee06d9': WebEngagement,
  '66a7091678a11bfed8614831': HrAssistant,
};

export const activeAssistantComponents = {
  'email': ActiveAssistant,
};
