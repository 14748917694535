import React, { useContext, useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Card, CardContent, CardTitle, CardFooter } from '../../../../components/ui/card';

import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { convertArrayToObjectV2, convertObjectToArray } from 'utils/arrayUtils';
import WorkflowMetadata from './WorkflowMetadata';
import { workflowService } from 'api';
import { Context } from 'context/GlobalState';
import { SubOrgContext } from 'context/SubOrganizationContext';
import { Label } from 'components/ui/label';

export default function WorkflowCard({ workflow }) {

    const [show, setShow] = useState(false)
    const [metadata, setMetadata] = useState([])

    useEffect(() => {
        setMetadata(convertObjectToArray(workflow.metadata || {}, 'key', 'value') || [])
    }, [workflow])

    return (
        <Card className="w-full space-y-8 p-4">
            <CardTitle> Workflow </CardTitle>
            <CardContent>
                <div className='flex flex-col gap-3'>
                    <div className='flex flex-col gap-2'>
                        <Label className='text-gray-500'> Name </Label>
                        <span className='font-[500]'>{workflow.name}</span>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <Label className='text-gray-500'> Created </Label>
                        <span className='font-[500]'>{new Date(workflow.created).toDateString()}</span>
                    </div>
                    <div className='flex flex-col gap-2'>
                        {/* <span className='font-[500] mb-3'>Workflow Metadata</span> */}
                        <Label className='text-gray-700 my-3'> Workflow Metadata </Label>

                        {metadata.map(item => (
                            <div className='flex flex-col gap-2' key={item.key}>
                                <Label className='text-gray-500'> {item.key} </Label>
                                <span className='font-[500]'>{item.value}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </CardContent>
            <CardFooter>
                <div className='flex w-full justify-end'>
                    <Button onClick={() => setShow(true)}> Edit Info </Button>
                </div>
            </CardFooter>
            <UpdateWorkflowDialog metadata={metadata} setMetadata={setMetadata} show={show} setShow={setShow} />
        </Card>
    )
}

const UpdateWorkflowDialog = ({ show, setShow }) => {

    const [btnLoading, setBtnLoading] = useState(false)
    const [metadata, setMetadata] = useState([])

    const { addNewNotifcation} = useContext(Context);
    const { actions, workflow } = useContext(SubOrgContext);


    const handleUpdateWorkflow = () => {

        setBtnLoading(true)

        const updatedObj = {
            organizationId: workflow.organizationId,
            subOrganizationId: workflow.subOrganizationId,
            workflowId: workflow._id,
            metadata: convertArrayToObjectV2(metadata, 'key', 'value')
        }

        console.log('updatedObj:', updatedObj);

        workflowService.updateWorkflow(updatedObj)
        .then((res) => {
            console.log('res:', res);
            actions({type: 'SET_WORKFLOW', payload: {...workflow, metadata: convertArrayToObjectV2(metadata, 'key', 'value')}})
            setShow(false)
            addNewNotifcation('workflow updated successfully', 'success')
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        }).finally(() => {
            setBtnLoading(false)
        })
        
    }

    useEffect(() => {
        setMetadata(convertObjectToArray(workflow.metadata || {}, 'key', 'value') || [])
    }, [])
    
    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="max-h-[650px] overflow-scroll w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">
                        Update Workflow
                    </DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">
                        Modify or add new workflow metadata
                    </DialogDescription>
                </DialogHeader>

                <div className="space-y-6">
                    <div className='flex flex-col gap-2'>
                        <Label> Name </Label>
                        <Input value={workflow.name} disabled />
                    </div>

                    <WorkflowMetadata metadata={metadata} setMetadata={setMetadata} />
                </div>

                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant="outline" onClick={() => setShow(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateWorkflow} disabled={btnLoading} className="px-5 py-3 text-md">
                        Update
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}