import { bexConfigHttpClient } from '../../clients';


/**
 * Service class for performing subscription operations.
 */
class ChannelService {

    /**
     * Fetches the sub organization channels.
     * @returns {Promise<Object>} The response from the server returns sub organization channels.
     */
    async getChannels(organizationId, subOrganizationId) {
        const response = await bexConfigHttpClient.get(`/channels/get-org-channels?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}`)
        return response;
    }

    /**
     * get temp email auth
     * @returns {Promise<Object>} The response from the server returns temp email auth
     */
    async getTempEmail(organizationId, subOrganizationId, email) {
        const response = await bexConfigHttpClient.get(`hotel-auth/get-email-auth-temp?organization_id=${organizationId}&sub_organization_id=${subOrganizationId}&email_auth=${email}`)
        return response;
    }

    /**
     * add new channel
     * @param {Object} data - The new channel object includes organizationId, subOrganizationId, topic, address and any extra info
     * @returns {Promise<Object>} The response from the server returns new channel document from mongoDB.
     */
    async addChannel(data) {
        const response = await bexConfigHttpClient.post(`channels/add-channel`, data)
        return response;
    }

    /**
     * update channel
     * @param {Object} data - The new channel object includes organizationId, subOrganizationId, topic, address and any extra info
     * @returns {Promise<Object>} The response from the server returns new channel document from mongoDB.
     */
    async updateChannel(data) {
        const response = await bexConfigHttpClient.put(`/channels/update-channel`, data)
        return response;
    }

    /**
     * update channels assistants
     * @param {Object} data - The update channels assistant object includes organizationId, subOrganizationId, array of channels id and assistantId
     * @returns {Promise<Object>} The response from the server returns new channel document from mongoDB.
     */
    async updateChannelsAssistants(data) {
        const response = await bexConfigHttpClient.put(`/channels/update-channels-assistants`, data)
        return response;
    }

    /**
     * add new channel
     * @param {Object} data - The new channel object includes organizationId, subOrganizationId, topic, address and any extra info
     * @returns {Promise<Object>} The response from the server returns new channel document from mongoDB.
     */
    async deleteChannel(organizationId, subOrganizationId, channelId) {
        const response = await bexConfigHttpClient.delete(`/channels/delete-channel?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}&channelId=${channelId}`)
        return response;
    }


}

const channelService = new ChannelService()
export { channelService }
