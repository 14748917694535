import React, { useContext, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "components/ui/card";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { SendHorizontal, Info, RotateCcw, Menu } from 'lucide-react';
import { DocumentList } from "./DocumentList";
import { ScrollArea } from "components/ui/scroll-area";
import { v4 as uuidv4 } from 'uuid';
import { QueryRAG, GetLLMResponse } from "pages/PromptingAndRagTraining/context/functions/documentChatOps";
import { DocChatContext } from 'pages/PromptingAndRagTraining/context/DocChatContext';
import { LoaderSpinner } from 'components/LoaderSpinner';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "components/ui/tooltip";
import { SampleList } from './SampleList';
import { Textarea } from "components/ui/textarea";
import { Checkbox } from "components/ui/checkbox"


const ChatDocument = () => {
    return (
        <div className="grid grid-cols-3 gap-4 h-full">
            <div className="col-span-1">
                <DocumentChatBox/>
            </div>
            <div className="col-span-2">
                <DocumentDisplay />
            </div>
        </div>
    );
};

export default ChatDocument;


const DocumentDisplay = () => {
    
    const {
        selectedDocuments, 
        selectedSample,
        displayDocs, setDisplayDocs,
        displayDocsSample, setDisplayDocsSample,
        selectedDocIndex, setSelectedDocIndex,
        isSidebarOpen, setIsSidebarOpen,
        getFileExtension
    } = useContext(DocChatContext);
    // 3
    const [loading, setLoading] = useState(false);
    

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleDocSelect = (index) => {
        setSelectedDocIndex(index);
    };
    // useEffect(() => {
    //     if (selectedDocuments.length > 0){
    //         setLoading(true);
    //     }else {
    //         setLoading(false);
    //     }
    //     console.log(displayDocs)
    // }, [selectedDocuments])
   



    const hideLoading = () => {
        setLoading(false);
    }

    return (
        <Card className="w-full flex flex-col justify-between h-[700px]">
            <CardHeader className="h-16 border-b-[1px] border-gray-200 px-6 py-4 flex flex-row items-center justify-between">
                <CardTitle>View Document</CardTitle>
                {loading && <LoaderSpinner />}
                <Button variant="ghost" size="icon" onClick={toggleSidebar}>
                    <Menu className="w-5 h-5"/>
                </Button>
            </CardHeader>

            <CardContent className="flex flex-row p-0 h-full">
                {/* Main Content Area */}
                <div className="flex-1">
                    {displayDocs.length > 0 || displayDocsSample.length > 0 ? (
                        
                        <div className="relative w-full h-[608px]">
                            <iframe 
                                src={displayDocsSample.length > 0 ? displayDocsSample[0]?.uri : displayDocs[selectedDocIndex]?.uri}
                                style={{ width: '100%', height: '100%', border: 'none' }}
                                title={`document-${selectedDocIndex}`}
                                onLoad={hideLoading}
                            ></iframe>
                            {
                                (displayDocsSample.length > 0 ? displayDocsSample[0]?.filetype : displayDocs[selectedDocIndex]?.filetype) === "pdf" ?
                                    <div className="">
                                        <div unselectable='on' class={`absolute bottom-6 left-6 p-1 bg-black bg-opacity-70 text-white rounded-md z-10t text-sm`}>&copy; CoolRIOTS</div>
                                        <div unselectable='on' class="absolute top-[1px] right-[1px] p-2 bg-[#323639] text-white z-10 text-sm w-[7vw] h-[2vw]"></div>
                                    </div>
                                :
                                (displayDocsSample.length > 0 ? displayDocsSample[0]?.filetype : displayDocs[selectedDocIndex]?.filetype) === "txt" ?
                                    <div unselectable='on' class={`absolute top-6 right-6 p-1 bg-black bg-opacity-70 text-white rounded-md z-10t text-sm`}>
                                        &copy; CoolRIOTS
                                    </div>
                                :
                                (displayDocsSample.length > 0 ? displayDocsSample[0]?.filetype : displayDocs[selectedDocIndex]?.filetype) === "xlsx" || 
                                (displayDocsSample.length > 0 ? displayDocsSample[0]?.filetype : displayDocs[selectedDocIndex]?.filetype) === "xls" ?
                                    <div className="">
                                        <div unselectable='on' class={`absolute top-6 left-6 p-1 bg-black bg-opacity-70 text-white rounded-md z-10t text-sm`}>&copy; CoolRIOTS</div>
                                        <div unselectable='on' class="absolute bottom-[1px] right-[1px] p-2 bg-[#444444] text-white z-10 text-sm w-[120px] h-[23px] bg-[#393b3e]"></div>
                                    </div>
                                :
                                (displayDocsSample.length > 0 ? displayDocsSample[0]?.filetype : displayDocs[selectedDocIndex]?.filetype) === "docx" || 
                                (displayDocsSample.length > 0 ? displayDocsSample[0]?.filetype : displayDocs[selectedDocIndex]?.filetype) === "doc" ?
                                    <div className="">
                                        <div unselectable='on' class={`absolute top-6 left-6 p-1 bg-black bg-opacity-70 text-white rounded-md z-10t text-sm`}>&copy; CoolRIOTS</div>
                                        <div unselectable='on' class="absolute bottom-0 right-0 p-2 bg-white text-white z-10 text-sm w-[130px] h-[22px]"></div>
                                    </div>
                                :
                                    <div className="">
                                        <div unselectable='on' class={`absolute top-6 left-6 p-1 bg-black bg-opacity-70 text-white rounded-md z-10t text-sm`}>&copy; CoolRIOTS</div>
                                        <div unselectable='on' class="absolute bottom-[1px] right-[1px] p-2 bg-[#444444] text-white z-10 text-sm w-[120px] h-[22px]"></div>
                                    </div>

                            }
                            
                            {/* <div unselectable='on' class="absolute bottom-[1px] right-[1px] p-2 bg-[#444444] text-white z-10 text-sm w-[120px] h-[23px]"></div> */}
                        </div>
                        
                    ) : (
                        <div className="flex items-center justify-center h-full">
                            <p>No documents available</p>
                        </div>
                    )}
                </div>

                {/* Sidebar with Document List */}
                {
                    selectedDocuments.length > 0 && isSidebarOpen
                    &&
                    <div className="w-1/4 p-2 border-l border-gray-200">
                        <h3 className="text-lg font-semibold mb-4">Selected Documents</h3>
                        <ul className="space-y-2 text-xs">
                            {displayDocs.map((doc, index) => (
                                <li 
                                    key={index}
                                    className={`cursor-pointer p-2 ${index === selectedDocIndex ? 'bg-blue-100' : 'bg-white'} hover:bg-blue-50 rounded`}
                                    onClick={() => handleDocSelect(index)}
                                >
                                    {doc.filename}
                                </li>
                            ))}
                        </ul>
                    </div>
                }
                {
                    selectedSample.length > 0  && isSidebarOpen
                    &&
                    <div className="w-1/4 p-2 border-l border-gray-200">
                        <h3 className="text-lg font-semibold mb-4">Selected Documents</h3>
                        <ul className="space-y-2 text-xs">
                            {displayDocsSample.map((doc, index) => (
                                <li 
                                    key={index}
                                    className={`cursor-pointer p-2 ${index === selectedDocIndex ? 'bg-blue-100' : 'bg-white'} hover:bg-blue-50 rounded`}
                                    onClick={() => handleDocSelect(index)}
                                >
                                    {doc.filename}
                                    asd
                                </li>
                            ))}
                        </ul>
                    </div>
                }
                
            </CardContent>

            <CardFooter className="">
                
            </CardFooter>
        </Card>
    );
};


const DocumentChatBox = () => {

    // here
    
    // const [docChatConvo, setDocChatConvo] = useState([{role: 'system', message: "Hi! I am AI Teacher. I am here to help you go through your course materials. You can ask me questions about your slides, lecture materials, etc. I will do my best to answer your questions.", _id: uuidv4()}]);
    
    const endOfMessagesRef = useRef(null);
    const {
        selectedDocuments, setSelectedDocuments,
        selectedSample, setSelectedSample,
        selectedMethod,
        docChatConvo, setDocChatConvo,
        user,
        loading, setLoading,
        OnSendPrompt,
        inputMessage, setInputMessage
    } = useContext(DocChatContext);
    const [isQuiz, setIsQuiz] = useState(false);

    const INFO = 
`The AI teacher uses the model 'mistralai/mixtral-8x7b-instruct-v01' which is trained to follow an instruction format:
<s> [INST] Instruction [/INST] Model answer</s>

This model has been optimised through supervised fine-tuning and direct preference optimisation (DPO) for careful instruction following.

Model Parameters:
Decoding: sampling
Max Tokens: 1300
Temperature: 0.7
Top K: 50
Top P: 1
Repetition Penalty: 1
Stopping Criteria: <|endoftext|>
`;


    const OnKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (event.shiftKey) {
                return;
            } else {
                event.preventDefault();
                OnSendPrompt(isQuiz);
            }
        }
    };

    const handleResetPrompt = () => {
        setSelectedSample([]);
        setSelectedDocuments([]);
        setDocChatConvo([{role: 'system', message: "Hi! I am AI Teacher. I am here to help you go through your course materials. You can ask me questions about your slides, lecture materials, etc. I will do my best to answer your questions.", _id: uuidv4()}]);
    }

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }, [docChatConvo]);

    return (
        <Card className="w-full flex flex-col justify-between min-h-[600px] h-fit">
            <CardHeader className="h-16 border-b-[1px] border-gray-200 px-6 py-4 flex flex-row items-center justify-between">
                <CardTitle>Chat with AI Teacher</CardTitle>
                <div className="flex items-center gap-2">
                    {/* <Combobox
                        items={METHODS}
                        selectedValue={METHODS.find(item => item.value === selectedMethod)}
                        setter={onSelectMethod}
                    /> */}
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Info className="h-5 w-5" />
                            </TooltipTrigger>
                            <TooltipContent className="max-w-[300px] whitespace-pre-line" align="center" side="bottom">
                                <p>{INFO}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <Button variant="ghost" size="icon" className="flex items-center" onClick={handleResetPrompt}>
                        <RotateCcw className="w-5 h-5" />
                    </Button>
                    <DocumentList/>
                    <SampleList inputMessage={inputMessage} setInputMessage={setInputMessage}/>
                </div>
            </CardHeader>

            <CardContent className="border-t flex flex-row gap-2 items-center py-2 px-0 h-full w-full">
                <ScrollArea className="max-h-[460px] min-h-[460px] h-full w-full">
                    <div className="py-4 px-3">
                        {docChatConvo.map((convo) => (
                            <div
                                key={convo._id}
                                className={`text-sm mb-4 flex ${convo.role === "user" ? "justify-end" : "flex-row gap-2"}`}>


                                <div className={`rounded-[16px] px-3 py-2 max-w-[90%] ${convo.role === "user" ? "bg-gray-200" : "w-full"}`}>
                                    <pre className='break-words whitespace-pre-wrap'>
                                        {convo.message}
                                    </pre>
                                </div>
                            </div>

                        ))}
                        {loading &&(
                            <div className="rag-typing mb-4">
                                <div className="dot bg-black"></div>
                                <div className="dot bg-black"></div>
                                <div className="dot bg-black"></div>
                            </div>
                        )}
                    </div>
                    <div ref={endOfMessagesRef} />
                </ScrollArea>
            </CardContent>

            <CardFooter className="border-t flex flex-row gap-2 items-center py-2 px-3">
                <Textarea
                    className="shadow-none outline-none focus:outline-none focus-visible:outline-none focus-visible:ring-0"
                    placeholder="Type something..."
                    value={inputMessage}
                    onChange={(e)=>{setInputMessage(e.target.value)}}
                    onKeyDown={OnKeyDown}
                />
                <Button variant="ghost" size="icon"
                onClick={() => OnSendPrompt(isQuiz)}
                disabled={inputMessage.trim() === "" || selectedDocuments.length === 0 && selectedSample.length === 0}>
                    <SendHorizontal className="w-5 h-5" />
                </Button>
                <div className="items-center flex space-x-2">
                    <Checkbox
                        id="quizMode"
                        checked={isQuiz}
                        onCheckedChange={(e) => setIsQuiz(!isQuiz)}
                    />
                    <label
                        htmlFor="quizMode"
                        className="text-sm font-medium leading-none break-keep"
                    >
                        Quiz Mode
                    </label>
                </div>
            </CardFooter>
        </Card>
    )
}