import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import './dashboard-styles.css'
import { Link, useParams } from 'react-router-dom'
import { Card, CardFooter, CardContent } from '../../components/ui/card';
import { Bot } from 'lucide-react';
import { AppLoader } from '../../components/LoaderSpinner'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../components/ui/select"
import { Label } from '../../components/ui/label'
import { Button } from '../../components/ui/button'
import { SubOrgContext } from 'context/SubOrganizationContext';
import { workflowService } from 'api';


export default function AssistantsDashboard() {

    const { workflow } = useContext(SubOrgContext)

    if (!workflow) {
        return <WorkflowRequired />;
    }

    return (
        <div className='dashboard-container'>
            <div className='inner-dashboard space-y-8'>
                <h2 className="text-lg font-extralight tracking-tight text-gray-900 sm:text-xl lg:text-4xl dark:text-gray-50 text-center border-b p-4">
                    BeX AI Assistant Center
                </h2>

                <div className='max-w-sm mx-auto p-10 grid gap-6 lg:grid-cols-3 items-start lg:max-w-none group'>
                    {workflow.workflowType?.assistants?.map((item) => (
                        <DashboardCard 
                            key={item._id}
                            {...item} />
                    ))}
                </div>
            </div>
        </div>
    )
}


export const DashboardCard = forwardRef((props, ref) => {
    const { _id, title, icon, description } = props 

    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
  
    useImperativeHandle(ref, () => ({
      getTitleRef: () => titleRef.current,
      getDescriptionRef: () => descriptionRef.current
    }));

    return (
        <div className={`bg-white dark:bg-gray-900 rounded-lg shadow-sm border-solid p-4 h-[280px] w-72 border-2 hover:border-red-500`}>
            <Link className="text-current no-underline" to={_id}>
                <div className="p-6 flex flex-col items-center justify-center space-y-4">
                    <div>
                        {icon || <Bot size={40} 
                        className="h-10 w-10 text-red-500" />}
                    </div>

                    <h3 className="text-lg font-light text-center">
                        {title}
                    </h3>

                    <p className="text-sm font-extralight text-gray-500 dark:text-gray-400 text-center">
                        {description}
                    </p>
                </div>
            </Link>
        </div>
    )
})

export const WorkflowRequired = () => {

    const [selectedWorkflow, setSelectedWorkflow] = useState(null)
    const [btnLoading, setBtnLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [workflowTypes, setWorkflowTypes] = useState([])
    const { oragID, subOragID } = useParams()
    const { selectedSubOrganization } = useContext(SubOrgContext)

    const handleWorkflowChange = (value) => {
        setSelectedWorkflow(workflowTypes.find(item => item.id === value))
    }

    const handleAddWorkflow = () => {

        const workflowObj = {
            "name": selectedWorkflow.name,
            "organizationId": oragID,
            "subOrganizationId": subOragID,
            "workflowAssistants": selectedWorkflow.assistants.map(id => ({assistant: id, status: 'inactive'})),
            'workflowType': selectedWorkflow._id
        }

        setBtnLoading(true)
        workflowService.addWorkflow(workflowObj)
        .then((res) => {
            console.log('res: ', res);
            window.location.reload()
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setBtnLoading(false)
        })

    }

    useEffect(() => {
        workflowService.getWorkflowTypes(selectedSubOrganization?.isMain ? 'main' : 'regular')
        .then((res) => {
            console.log('res: ', res);
            setWorkflowTypes(res.data?.map((item) => ({id: item._id, ...item})))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    if (loading) {
        return <div className='flex flex-grow'> <AppLoader size={50} /> </div>;
    }

    return (
        <div className="flex flex-col items-center justify-center flex-grow">
            <div className="max-w-md w-full space-y-4 px-4">
                <div className="text-center space-y-2">
                    <h1 className="text-3xl font-bold">Choose Your Workflow</h1>
                    <p className="text-muted-foreground">
                        Select the workflow for your sub-organization to use BeX AI assistants.
                    </p>
                </div>
                <Card>
                    <CardContent className="p-6 space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="workflow">Workflow</Label>
                            <Select onValueChange={handleWorkflowChange} id="workflow" className="w-full">
                                <SelectTrigger>
                                    <SelectValue placeholder="Select a workflow" />
                                </SelectTrigger>
                                <SelectContent>
                                    {workflowTypes.map((item) => (
                                        <SelectItem key={item.id} value={item.id}>{item.name}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </CardContent>
                    <CardFooter className="flex justify-end">
                        <Button disabled={!selectedWorkflow || btnLoading} onClick={handleAddWorkflow}>Proceed to BEX AI</Button>
                    </CardFooter>
                </Card>
            </div>
        </div>
    )
}