import React from 'react'
import { NewChannelInput } from './AuthenticateEmail'

import { Button } from "components/ui/button"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "components/ui/dialog"
import { Label } from "components/ui/label"
import { Input } from "components/ui/input"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Context } from "context/GlobalState"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { assistantInstanceService, channelService } from "api"
import CodeDisplay from 'components/ui/CodeDisplay'
import { v4 as uuidv4 } from 'uuid';
import { server } from 'utils/httpsRequest'


export default function WebChatSetup({ newChannelObj, setNewChannelObj }) {

    const { oragID, subOragID } = useParams()

    useEffect(() => {
        const emailChannelObj = {
            organizationId: oragID,
            subOrganizationId: subOragID,
            isAssistantDisabled: false,
            type: 'Webchat',
            widgetId: uuidv4()
        }
        setNewChannelObj(prev => ({...prev, ...emailChannelObj}))
    }, [])

    return (
        <div>
            <NewChannelInput newChannelObj={newChannelObj} setNewChannelObj={setNewChannelObj} channelType={'webchat'} />
        </div>
    )
}



export const WebChannelUpdateDialog = ({ isOpen, setOpenEmailModal, emailUpdateObject, setEmailUpdateObject, setEmailChannels }) => {

    const [btnLoading, setBtnLoading] = useState(false)
    const [assistants, setAssistants] = useState([])
    const [assistantsLoading, setAssistantsLoading] = useState(true)
    const { oragID, subOragID } = useParams()
    const { addNewNotifcation } = useContext(Context)

    useEffect(() => {
        if (!isOpen) return
        assistantInstanceService.getSubOrgAssistantInstance(oragID, subOragID)
        .then((res) => {
            console.log('res: ', res);
            // filter only channel assistants
            setAssistants(res.data.filter((item) => item.assistantId.channelType === emailUpdateObject?.type?.toLowerCase() || ''))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setAssistantsLoading(false)
        })
    }, [isOpen])

    const handleOnSubmit = () => {

        setBtnLoading(true)

        const channelObj = {
            organizationId: oragID,
            subOrganizationId: subOragID,
            channelId: emailUpdateObject._id,
            topic: emailUpdateObject.topic,
            address: emailUpdateObject.address,
            assistantId: emailUpdateObject?.assistantId === 'none' ? '' : emailUpdateObject?.assistantId
        }

        channelService.updateChannel(channelObj)
        .then((res) => {
            console.log('res: ', res);
            setEmailChannels(prev => {
                return prev.map((item) => {
                    if (item._id === emailUpdateObject._id){
                        return {...emailUpdateObject}
                    } else {
                        return {...item}
                    }
                })
            })
            addNewNotifcation('Email Updated successfully', 'success')
            setOpenEmailModal(false)
        }).catch(error => {
            console.log('error: ', error);
            if (error.response?.status === 404) {  
                addNewNotifcation('Email not found', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })

    }

    const handleChange = (value, type) => {
        setEmailUpdateObject(prev => {
            return {...prev, [type]: value}
        })
    }

    const code = `<script>
    (function() {
        var widgetId = '${emailUpdateObject?.widgetId || ''}';
        var script = document.createElement('script');
        script.src = '${server}/static/chat-widget.js';
        script.async = true;
        script.onload = function() {
            window.initializeChatWidget({
                widgetId: widgetId,
            });
        };
        document.head.appendChild(script);
    })();
</script>`

    if (!emailUpdateObject) return 

    return (
        <Dialog open={isOpen} onOpenChange={setOpenEmailModal}>
            <DialogContent className="sm:max-w-[600px] max-h-[650px] overflow-scroll">
                <DialogHeader>
                    <DialogTitle> Manage Channel </DialogTitle>
                    <DialogDescription> Update channel information</DialogDescription>
                </DialogHeader>
                <div className="p-6 space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="address">Address</Label>
                        <Input id='address' value={emailUpdateObject.address} onChange={(e) => handleChange(e.target.value, 'address')} />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="topic">Email Topic</Label>
                        <Input id='topic' value={emailUpdateObject.topic} onChange={(e) => handleChange(e.target.value, 'topic')} placeholder="Enter email topic" />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="assistant">Assistant</Label>
                        
                        <Select onValueChange={(value) => handleChange(value, 'assistantId')} disabled={assistantsLoading} id="assistant" defaultValue={emailUpdateObject?.assistantId || 'none'}>
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select an assistant" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="none">None</SelectItem>
                                {assistants.map(item => (
                                    <SelectItem key={item._id} value={item._id}>{item.title}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>

                    </div>

                    <div className="space-y-2">
                        <Label>Embed Web Chat</Label>
                        <CodeDisplay code={code} />
                    </div>
                </div>

                <DialogFooter>
                    <Button onClick={() => setOpenEmailModal(false)} variant="outline">Cancel</Button>
                    <Button onClick={handleOnSubmit} disabled={btnLoading}> Update </Button>
                </DialogFooter>
            </DialogContent>

        </Dialog>
    )
}
