import { Card, CardHeader, CardContent } from "components/ui/card"
import { useContext, useEffect, useState } from "react"
import ChannelType from "./ChannelType"
import AuthenticateEmail from "./AuthenticateEmail"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Context } from "context/GlobalState"
import { Button } from "components/ui/button"
import { channelService } from "api"
import { AppLoader } from "components/LoaderSpinner"

export default function Channel() {

    const [emailChannels, setEmailChannels] = useState([])
    const [channelDialog, setChannelDialog] = useState(false)
    const [loading, setLoading] = useState(true)


    const { oragID, subOragID } = useParams()
    const { addNewNotifcation } = useContext(Context)

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const success = searchParams.get("email-auth-success");
    const statusCode = searchParams.get("statusCode");

    const handleAddEmailChannel = () => {
        setChannelDialog(true)
    }

    const handleAddWebChatChannel = () => {

    }

    const fetchEmailChannels = () => {
        channelService.getChannels(oragID, subOragID)
        .then((res) => {
            console.log('res: ', res);
            setEmailChannels(res.data)
        }).catch(error => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchEmailChannels()

        if (success === 'false') {
            if (statusCode === '403' || statusCode === 403) {
                addNewNotifcation('Error. Please check your email configuration', 'danger')
            } else if (statusCode === '409' || statusCode === 409) {
                addNewNotifcation('Error. Email already subscribed', 'danger')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'danger')
            }
            const newUrl = location.pathname;
            navigate(newUrl, { replace: true });
        } else if (success === 'true') {
            setChannelDialog(true)
        }
    }, [])



    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    return (
        <div className="w-full">
            <div className='py-4 h-auto'>
                <Card className='relative h-auto'>
                    <CardHeader>
                        <div className='flex w-full justify-between'>
                            <h1 className="text-2xl font-bold"> Communication Channels </h1>
                            <Button onClick={handleAddEmailChannel}>Add Channel</Button>
                        </div>
                    </CardHeader>
                    <CardContent className="space-y-8">
                        {/* new channel dialog */}
                        <AuthenticateEmail isOpen={channelDialog} setOpenEmailModal={setChannelDialog} setEmailChannels={setEmailChannels} />
                        
                        {/* channel email type */}
                        <ChannelType type='Email' setEmailChannels={setEmailChannels} channels={emailChannels.filter(item => item.type === 'Email')}  />

                        {/* channel webchat type */}
                        <ChannelType onClick={handleAddWebChatChannel} type='Web Chat' setEmailChannels={setEmailChannels} channels={emailChannels.filter(item => item.type === 'Webchat')}  />
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}
