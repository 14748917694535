import React, { useContext, useEffect, useState } from 'react'
import './email-extraction-styles.css'
import { useParams } from 'react-router-dom';
import { Context } from '../../../context/GlobalState';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../components/ui/dialog"
import { Button } from "../../../components/ui/button"
import { Label } from "../../../components/ui/label"
import { Input } from "../../../components/ui/input"
import { assistantInstanceService, channelService } from 'api';
import { Textarea } from 'components/ui/textarea';
import MultiSelectComponent from 'components/ui/MultiSelect';
import { useChannels } from 'hooks/useChannels';
import { SubOrgContext } from 'context/SubOrganizationContext';


export const EmailSetupModal = ({ isOpen, setOpenEmailModal }) => {

    return (
        <HotelSetup isOpen={isOpen} setOpenEmailModal={setOpenEmailModal} />
    );
};


export default function HotelSetup({ isOpen, setOpenEmailModal }) {

    const { oragID, subOragID, assistantId } = useParams()
    const [loading, setLoading] = useState(false);
    const [selectedChannels, setSelectedChannels] = useState([]);
    const [title, seTitle] = useState('');
    const [description, setDescription] = useState('');
    const { channels, loading: channelsLoading } = useChannels(oragID, subOragID, 'Email');

    const { workflow } = useContext(SubOrgContext)
    const assistantObj = workflow.workflowType?.assistants?.find(item => item._id === assistantId)

    const { addNewNotifcation } = useContext(Context)
    
    const handleOnSubmit = async () => {
        setLoading(true)

       try {
            // add new assistant instance
            const updateOrg = {
                title: title,
                description: description,
                assistantId: assistantId,
                status: 'active',
                organizationId: oragID,
                subOrganizationId: subOragID,
            }
            const addAssistantRes = await assistantInstanceService.addAssistantInstance(updateOrg)
            console.log('addAssistantRes: ', addAssistantRes);


            const channelsObj = {
                organizationId: oragID,
                subOrganizationId: subOragID,
                channels: selectedChannels,
                assistantId: addAssistantRes.data._id
            }
            await channelService.updateChannelsAssistants(channelsObj)

            addNewNotifcation('Assistant activated successfully.','success', 4)

            // redirect user to assistant page
            window.location = `/organizations/${oragID}/sub/${subOragID}/assistants`
        } catch(error) {
            console.log('error: ', error);
            addNewNotifcation('Hotel creation failed. Please check all your entries.','danger')
        } finally {
            setLoading(false)
        }
    }

    const onChannelSelect = (list) => {
        setSelectedChannels(list)
    }

    useEffect(() => {
        seTitle(assistantObj.title)
        setDescription(assistantObj.description)
    }, [])

    const isButtonDisabled = () => {
        return (
            title === '' ||
            description === '' ||
            !selectedChannels.length > 0
        );
    };


    return (
        <Dialog open={isOpen} onOpenChange={setOpenEmailModal}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Email Handling Setup</DialogTitle>
                    <DialogDescription>Let's get started by authenticating your Outlook email.</DialogDescription>
                </DialogHeader>
                <div className="p-6 space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="name" className="text-md">Name</Label>
                        <Input
                            value={title}
                            onChange={(e) => seTitle(e.target.value)}
                            id="name"
                            placeholder="Enter assistant name"
                            className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="description" className="text-md">Description</Label>
                        <Textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            id="description"
                            placeholder="Enter assistant description"
                            className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                    </div>

                    <div className="space-y-2 flex flex-col">
                        <Label htmlFor="email-channel" className="text-md">Email channel</Label>
                        <MultiSelectComponent disabled={channelsLoading} selectedItems={selectedChannels} 
                        items={channels.filter(channel => channel.assistantId === '' || channel.assistantId === null).map(item => ({value: item._id, label: item.address}))}
                        placeholder='Channel' onItemsSelect={onChannelSelect} className='w-full' />
                    </div>

                </div>
                <DialogFooter>
                    <Button onClick={() => setOpenEmailModal(false)} variant="outline">Cancel</Button>
                    <Button onClick={handleOnSubmit} disabled={loading || isButtonDisabled()}>Activate Assistant</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
