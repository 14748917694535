import { httpRequestO, httpRequestX } from "../../../../utils/httpsRequest";
import { ragService } from "api/services/PROJECT-O"
import config from "pages/PromptingAndRagTraining/ailab.config";

// Prompt 1
// const SYSTEM_PROMPT = 
//     "You are Granite Chat, an AI language model developed by IBM. " +
//     "You are a cautious assistant. You carefully follow instructions. " +
//     "You are helpful and harmless and you follow ethical guidelines and promote positive behavior. ";
// const INITIAL_USER_PROMPT = 
//     "You are an AI language model designed to function as a specialized " +
//     "Retrieval Augmented Generation (RAG) assistant. When generating responses, " +
//     "prioritize correctness, i.e., ensure that your response is grounded in " +
//     "context and user query. When there is no information in the document, " +
//     "respond with \"Sorry, I cannot find that information from the document. " +
//     "Can you rephrase that?\".";
// const SYSTEM_PROMPT = 
//     "You are an assistant called \"AI Teacher\". " +
//     "You are a cautious assistant. You carefully follow instructions by user. " +
//     "You are helpful and harmless and you follow ethical guidelines and promote positive behavior. ";
// const INITIAL_USER_PROMPT = 
//     "You are an assistant that generates questions for quizzes based on the documents' contents. " +
//     "You also specialize in scoring the answers of the users. " +
//     "When generating quizzes, you must always include the correct answers along with the questions. " +
//     "Do not generate"

// Prompt 2
// const SYSTEM_PROMPT_2 = 
//     "You are Granite Chat, an AI language model developed by IBM. " +
//     "You are a cautious assistant. You carefully follow instructions. " +
//     "You are helpful and harmless and you follow ethical guidelines and promote positive behavior. " +
//     "You are aware of the your recent conversations aside from the documents provided.";
// const INITIAL_USER_PROMPT_2 = 
//     "You are an AI language model designed to function as a specialized " +
//     "Retrieval Augmented Generation (RAG) assistant. When generating responses, " +
//     "prioritize correctness, i.e., ensure that your response is grounded in " +
//     "context and user query. Always make sure that your response is relevant " +
//     "to the question.";
// single word, concise, narrative

// const ANSWER_LENGTH = "Answer Length: concise";


// Go to this link for more information about the language model and the prompt style
// https://dataplatform.cloud.ibm.com/docs/content/wsj/analyze-data/fm-models-ibm-chat.html?context=wx#factual-answers
export const GetLLMResponse = async (rag, query, convo, quiz) => {
    try{
        let pastConversation = "";
        // console.log(convo)
        for (const item of convo){
            if (item.role === "user"){
                pastConversation += "User:\n";
            }else{
                pastConversation += "You:\n";
            }
            pastConversation += item.message.trim() + "\n\n";
        }

        // let prompt = "<|system|>\n" + SYSTEM_PROMPT + "\n";

        // Prompt 1
        // prompt += pastConversation.length > 0 ? pastConversation + "" : "";
        // prompt += "<|user|>\n" + INITIAL_USER_PROMPT + "\n";
        // prompt += ANSWER_LENGTH + "\n";
        // prompt += rag ? rag : "No information found in documents.\n";
        // prompt += query + "\n";
        // prompt += "<|assistant|>";
        // console.log(pastConversation)
        // Prompt 2
        // prompt += "User:\n" + INITIAL_USER_PROMPT + "\n\n"; 
        // prompt += ANSWER_LENGTH + "\n\n";
        // prompt += rag ? rag : "[Document]\nNo information found in documents.\n[End]\n\n";
        // prompt += pastConversation.length > 0 ? "Past Conversation:\n" + pastConversation : "";
        // prompt += "User:\n" + query + "\n\n";
        // prompt += "You:\n";


// let prompt = 
// "<s>\n" +
// "[INST]\n" +
// rag +
// "Based on the documents," + "\n" +
// query + "\n\n" +
// `${!quiz ?
//     "Include the page number and document title if provided.\n" :
//     "Always include the correct answers. Always Include the page number or page range of the answers. Do not repeat items or phrases. When there are more than one answers in the multiple-choice types of quizzes, state all the correct answers within the choices. Respond with information grounded in context of the document.\n"}` +
// "[/INST]";
let prompt = 
"<s>\n" +
"[INST]\n" +
rag + 

`${!quiz ? 
`
When responding, follow these guidelines:

1. Always include the page number and document title, if available.
2. Avoid repeating items or phrases.
3. Ensure your responses are concise and grounded on the document's context.
`
:
`
You are AI specialized in generating Quizzes.
When responding, follow these guidelines:

1. Always include the page number and document title where the answers are found.
2. Always include the correct answer.
3. Multiple choice types shall have multiple choices and not one only.
4. Each item shall strictly have one correct answer only.
5. Avoid repeating items or phrases.
6. Ensure your responses are concise and grounded on the document's context.
`
}
` +
"Based on the documents," + "\n" +
query + "\n\n" +
"[/INST]";


        console.log(prompt);

        const response = await httpRequestO.post('/projecto/api/v1/model/foundationModel/experiment?mode=freeMode', {
            // modelId: 'ibm/granite-1 3b-chat-v2',
            modelId: 'mistralai/mixtral-8x7b-instruct-v01',
            parameters: {
                mode: "sampling",
                max_tokens: 16384,
                max_new_tokens: 1300,
                stop_sequences: ["<|endoftext|>"],
                repetition_penalty: 1,
                top_k: 50,
                top_p: 1,
                temperature: 0.7
            },
            // parameters: { mode: "sampling", max_tokens: 0, max_new_tokens: 500, top_k: 50, top_p: 0.7, temperature: 0.7, stop_sequences: ["<|endoftext|>"], repetition_penalty: 1},
            prompt: prompt,
            query: ""
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result = response.data.prediction;
        return result;
        // console.log("prompt response", response);
    }
    catch (error){
        console.log(error);
    }
}


export const QueryRAG = async (documents, query, method, user, subOrg) => {
    // console.log(documents)
    if (method === "text_embedding")
        return await QueryWithEmbedding(documents, query, user, subOrg);
}



// Text Embedding
const getFileExtension = (url) => {
    return url.split('.').pop().split(/\#|\?/)[0];
};
const QueryWithEmbedding = async (documents, query, user, sample=false, quiz) => {
    if (quiz)
        query = "Summary of the document"
    try {
        let rag = "";
        if (documents.length > 0) {
            const totalK = 20;
            const numberOfDocs = documents.length;
            const baseK = Math.floor(totalK / numberOfDocs);
            const remainderK = totalK % numberOfDocs;

            for (let i = 0; i < numberOfDocs; i++) {
                const doc = documents[i];
                const formData = new FormData();
                let org = sample ? process.env.REACT_APP_COMMON_ORGANIZATION_ID : user.organization;
                let subOrg = sample ? process.env.REACT_APP_COMMON_SUB_ORGANIZATION_ID : process.env.REACT_APP_DEMO_MODE;
                formData.append('organization_id', org);
                formData.append('sub_organization_id', subOrg);
                formData.append('query', query);
                formData.append('document_id', doc._id);

                // Distribute the remainder
                const k = baseK + (i < remainderK ? 1 : 0);
                formData.append('k', k);

                const res = await httpRequestO.post('/projecto/api/v1/embedding_api_service/query_data/', formData);
                rag += ConstructEmbeddingResult(res.data.data.documents);
                // console.log("RAGE",rag);
            }
        }
        return rag;
    }
    catch (e) {
        console.log("QueryWithEmbedding Error:", e);
    }
}

const ConstructEmbeddingResult = (documents) => {
    console.log(documents)
    let str = "";
    documents.forEach((doc) => {
        // str += "[Document]\n";
        // str += `${doc.metadata.name} (Page: ${doc.metadata.slide_number})\n`;
        // str += `${doc.page_content}\n`;
        // // str += `Page: ${doc.metadata.slide_number}\n`;
        // str += "[End]\n";
        // console.log(doc)
        let ext = getFileExtension(doc.metadata.name);
        let ppt = ext === "ppt" || ext === "pptx" || ext === "PPT" || ext === "PPTX";
        let pdf = ext === "pdf" || ext === "PDF";
        let docx = ext === "docx" || ext === "DOCX";


        str += "[Document]\n";
        str += ppt ? `[Page]${doc.metadata.slide_number}[/Page]\n` : "";
        str += (pdf || docx) ? `[Page]${doc.metadata.start_page} to ${doc.metadata.end_page}[/Page]\n` : "";
        str += `[Title]${doc.metadata.name}[/Title]\n`;
        str += `[Content]${doc.page_content}[/Content]\n`;
        // str += ppt ? `[Page ${doc.metadata.slide_number}]\n` : "";
        str += "[/Document]\n\n";
    })
    return str;
}




function getFileWithLowercaseExtension(file) {
    const fileName = file.name;
    const lastDotIndex = fileName.lastIndexOf('.');
    const nameWithoutExtension = fileName.slice(0, lastDotIndex);
    const extension = fileName.slice(lastDotIndex + 1);
    const lowercasedExtension = extension.toLowerCase();
    const newFileName = `${nameWithoutExtension}.${lowercasedExtension}`;
    // console.log("New File Name:", newFileName, new File([file], newFileName, { type: file.type }));
    return new File([file], newFileName, { type: file.type });

}
export const processMultipleFiles = async (files, filter, user) => {

    try {
        const results = await Promise.all(files.map(async (file, index) => {
            const form = new FormData();


            form.append('organization_id', user.organization);
            form.append('sub_organization_id', process.env.REACT_APP_DEMO_MODE);
            form.append('bucket_name', `${user.organization}-bex-demo-mode`);
            form.append('chunk_size', 700);
            form.append('chunk_overlap', 90);
            let newFile = getFileWithLowercaseExtension(file);
            form.append('files', newFile);

            let filter2 = { ...filter };
            filter2.name = newFile.name;

            form.append('metadata', JSON.stringify(filter2));
            // for (const [key, value] of form.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            try {
                const res = await ragService.processFile(form);

                if (!res.data || res.data.document_ids.length === 0) {
                    console.error(`No document IDs received for file at index ${index}.`);
                    return null;
                }

                return res.data;
            } catch (error) {
                console.error(`Error processing file at index ${index}:`, error);
                return null;
            }
        }));
        console.log(results);

        // Filter out null results and return the processed data
        return results.filter(result => result !== null && result !== undefined);

    } catch (error) {
        console.log("FILE PROCESSING ERROR", error);
        return [];
    }
}


export const deleteDocument = async (id, user, addNewNotifcation) => {
    
    try{
        await ragService.deleteDocument(user.organization, process.env.REACT_APP_COMMON_SUB_ORGANIZATION_ID, id, `${user.organization}-bex-demo-mode`)
        addNewNotifcation('Successfully deleted the document.', 'success');
    }
    catch (error){
        console.log(error);
        addNewNotifcation('Something went wrong. Could not delete the document.', 'danger');
        // throw new Error(error);
    }
};