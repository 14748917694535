import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../context/GlobalState';
import { Combobox } from './ui/combobox';
import CreateSubOrganizationDialog from './AddSubOrgForm';
import { SubOrgContext } from '../context/SubOrganizationContext';

const SubOrgDropdown = ({ urlSubOrg }) => {
    const [subOrgs, setSubOrgs] = useState([]);
    const [show, setShow] = useState(false);

    const { user, addNewNotifcation } = useContext(Context);
    const { actions, selectedSubOrganization } = useContext(SubOrgContext);
  
    useEffect(() => {
      setSubOrgs(user?.subOrganizations);
    }, [user]);

    const onSelect = (value) => {
      actions({ type: "SET_SELECTED_SUB_ORGANIZATION", payload: value });
      localStorage.setItem('selectedSubOrg', value._id);
      window.location.href = `/organizations/${user.organization}/sub/${value._id}/bex-assistants`;
    };

    return (
      <>
        {user.role === 'superAdmin' || user.role === 'admin' ? (
          <Combobox
            items={subOrgs}
            selectedValue={urlSubOrg || selectedSubOrganization}
            setter={onSelect}
            buttonProps={{
              text: 'Add Sub-Organization',
              onClick: () => setShow(true)
            }}
          />
        ) : (
          <Combobox
            items={subOrgs}
            selectedValue={urlSubOrg || selectedSubOrganization}
            setter={onSelect}
          />
        )}

        <CreateSubOrganizationDialog
          show={show}
          setShow={setShow}
          subOrgs={subOrgs}
          setSubOrgs={setSubOrgs}
          user={user}
          addNewNotifcation={addNewNotifcation}
        />
      </>
    );
};

export default SubOrgDropdown;
