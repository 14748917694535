import { assistantInstanceService } from 'api'
import { DashboardCard } from 'pages/Dashboard/AssistantsDashboard'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

export default function Assistants() {

    const [assistantInstances, setAssistantInstances] = useState([])
    const [loading, setLoading] = useState(true)

    const { oragID, subOragID } = useParams()
    const assistantFormRef = useRef(null);


    useEffect(() => {

        assistantInstanceService.getSubOrgAssistantInstance(oragID, subOragID)
        .then((res) => {
            setAssistantInstances(res.data)
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })

    }, [])

    return (
        <div className='flex flex-grow'>
            {loading ? (
                <div>
                </div>
            ) : (
                <div className='dashboard-container'>
                    <div className='inner-dashboard space-y-8'>
                        <h2 className="text-lg font-extralight tracking-tight text-gray-900 sm:text-xl lg:text-4xl dark:text-gray-50 text-center border-b p-4">
                            BeX AI Assistants
                        </h2>
        
                        {assistantInstances.length > 0 ? (
                            <div className='max-w-sm mx-auto p-10 grid gap-6 lg:grid-cols-3 items-start lg:max-w-none group'>
                                {assistantInstances?.map((item) => (
                                    <DashboardCard
                                        ref={assistantFormRef}
                                        key={item._id}
                                        {...item} />
                                ))}
                            </div>
                        ) : (
                            <div className='flex flex-grow justify-center items-center'>
                                <span>No Activated Assistant Found </span>
                            </div>
                        )}
                        
                    </div>
                </div>
            )}
        </div>
    )
}
