import React, { useContext, useState } from 'react'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Textarea } from 'components/ui/textarea';
import { Label } from 'components/ui/label';
import { Context } from 'context/GlobalState';
import { useParams } from 'react-router-dom';
import { assistantInstanceService } from 'api';
import { Button } from 'components/ui/button';

export default function UpdateAssistantDialog({ openModal, setOpenModal, assistant, setAssistant }) {

    const [editedAssistant, setEditedAssistant] = useState(assistant)
    const [btnLoading, setBtnLoading] = useState(false)

    const { addNewNotifcation } = useContext(Context)
    const { oragID, subOragID } = useParams()


    const handleUpdateAssistant = async (id) => {

        if (!editedAssistant.title || !editedAssistant.description) {
            return addNewNotifcation('Please fill in all fields', 'warning')
        }

        setBtnLoading(true)
        const updateObj = {
            id: editedAssistant._id,
            organizationId: oragID,
            subOrganizationId: subOragID,
            title: editedAssistant.title,
            description: editedAssistant.description
        }

        try {
            const res = await assistantInstanceService.updateAssistantInstance(updateObj)
            console.log('res: ', res);
            setAssistant(prev => ({...prev, title: editedAssistant.title, description: editedAssistant.description}))
            addNewNotifcation('assistant info updated successfully', 'success')
            setOpenModal(false)
        } catch(error) {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        } finally {
            setBtnLoading(false)
        }
    }



    return (
        <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogContent className="max-h-[650px] overflow-scroll w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">Update BeX Assistant</DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">Customize your BeX assistant.</DialogDescription>
                </DialogHeader>
                <div className="space-y-6">
                    <div className="space-y-3">
                        <Label htmlFor="name" className="text-md">Name</Label>
                        <Input
                            value={editedAssistant.title} 
                            onChange={(e) => setEditedAssistant(prev => ({...prev, title: e.target.value}))}
                            id="name"
                            placeholder="Enter assistant name"
                            className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                    </div>
                    <div className="space-y-3">
                        <Label htmlFor="description" className="text-md">Description</Label>
                        <Textarea
                            value={editedAssistant.description}
                            onChange={(e) => setEditedAssistant(prev => ({...prev, description: e.target.value}))}
                            id="description"
                            placeholder="Enter assistant description"
                            className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                    </div>
                </div>
                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">
                        Cancel
                    </Button>
                    <Button disabled={btnLoading} onClick={handleUpdateAssistant} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground text-white">
                        Update
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
