import '../prompting-and-rag-training.css';
import PromptPanelDemo from "./Demo/PromptPanelDemo.jsx";
import PromptPanelLive from "./Live/PromptPanelLive.jsx";
import CodeSandbox from './CodeSandbox/CodeSandbox';
import ChatDocument from './ChatDocument/ChatDocument';
import { DocChatProvider } from '../context/DocChatContext';
import { SandboxProvider } from '../context/SandboxContext';
import {
Tabs,
TabsContent,
TabsList,
TabsTrigger,
} from "../../../components/ui/tabs";



export default function Training() {
    return (
        <Tabs defaultValue="demo" className="w-full p-0 h-fit">
            <TabsList className="flex w-full grid-cols-4 bg-gray-100">
                <TabsTrigger className="" value="demo">Demo Prompt</TabsTrigger>
                <TabsTrigger value="live">Live Prompt</TabsTrigger>
                <TabsTrigger value="code">Code Sandbox</TabsTrigger>
                <TabsTrigger value="teacher">AI Teacher</TabsTrigger>
            </TabsList>
            <TabsContent className="" value="demo">
                <PromptPanelDemo/>
            </TabsContent>
            <TabsContent className="" value="live">
                <PromptPanelLive/>
            </TabsContent>
            
            <SandboxProvider>
                <TabsContent value="code">
                    <CodeSandbox/>
                </TabsContent>
            </SandboxProvider>
            
            <DocChatProvider>
                <TabsContent value="teacher">
                    <ChatDocument/>
                </TabsContent>
            </DocChatProvider>

        </Tabs>
    );
}



