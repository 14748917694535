import React, { useContext, useState, useEffect, useRef } from 'react';
import { Button } from "../../../../components/ui/button";
import { FileText, Trash2, Upload } from 'lucide-react';
import { PartContext } from "../../context/PartContext";
import * as ShadeDD from "../../../../components/ui/dropdown-menu";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogClose,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../../components/ui/dialog";
import { LoaderSpinner } from '../../../../components/LoaderSpinner';
import { Input } from "../../../../components/ui/input";


export const DocumentList = ({messagesLive, setMessagesLive, handleFileChange, fileInputRef}) => {

    const [isLoading, setIsLoading] = useState(true);

    const handleIfSelected = (doc) => {
        // console.log("handleIfSelected", doc);
        if (
          selectedDocuments.find(
            (document) => document._id === doc._id
          )
        )
          return true;
        else return false;
      };
    
    const {
        user,
        getDocList,
        selectedDocuments, setSelectedDocuments,
        prevSelectedDocumentsRef, files,
        uploadedDocs, setUploadedDocs,
        loadDocuments,
        deleteDocument,
        processDocuments,
        fileProcessLoading
    } = useContext(PartContext);

    const GetDocs = async () => {
        await loadDocuments();
        setIsLoading(false);
    }

    useEffect(() => {
        GetDocs();
    }, []);

    useEffect(() => {
        // console.log("Current Selected Documents: ", selectedDocuments);
        // console.log("Previous Selected Documents: ", prevSelectedDocumentsRef.current);

        // Logic to handle changes between previous and current selectedDocuments
        if (selectedDocuments.length === 0 && prevSelectedDocumentsRef.current.length > 0) {
            const selectMessage = { role: 'ref', msg: "Removed reference to document.", _id: Date.now() };
            setMessagesLive(prevMessages => [...prevMessages, selectMessage]);
        } else if (selectedDocuments.length > 0) {
            let selectMessage = { role: 'ref', msg: '', _id: Date.now() };

            const names = selectedDocuments.slice(0, 2).map(doc => doc.name);
            const moreNamesCount = selectedDocuments.length - 2;

            if (selectedDocuments.length > 2) {
                selectMessage.msg = `Referencing: ${names.join(', ')} + ${moreNamesCount} more`;
            } else {
                selectMessage.msg = `Referencing: ${names.join(', ')}`;
            }

            const lastMessage = messagesLive[messagesLive.length - 1];
            if (lastMessage && lastMessage.role === 'ref' && lastMessage.msg === selectMessage.msg) {
                return; // Exit early if the message already exists
            }
            setMessagesLive(prevMessages => [...prevMessages, selectMessage]);
        }

        // Update prevSelectedDocumentsRef to current selectedDocuments after processing
        prevSelectedDocumentsRef.current = selectedDocuments;
    }, [selectedDocuments]);

    return(
        <ShadeDD.DropdownMenu>
            <ShadeDD.DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon" className="px-2">
                    <FileText className="w-5 h-5" />
                </Button>
            </ShadeDD.DropdownMenuTrigger>
            <ShadeDD.DropdownMenuContent align="start" side="top" className="w-[350px]">
                <ShadeDD.DropdownMenuLabel>
                    {isLoading ? "Loading documents..." : uploadedDocs.length === 0 ? "No documents" : "Documents"}
                    {/* {uploadedDocs.length === 0 ? "No documents" : "Documents"} */}
                </ShadeDD.DropdownMenuLabel>
                <ShadeDD.DropdownMenuSeparator />

                {uploadedDocs.map((doc) => (
                    <div
                        key={doc._id}
                        className="flex flex-row flex-nowrap justify-between gap-1">
                        <ShadeDD.DropdownMenuCheckboxItem
                            className="w-full overflow-hidden"
                            
                            checked={handleIfSelected(doc)}
                            onSelect={(event) => {
                            const result = selectedDocuments.find(
                                (document) => document._id === doc._id
                            );
                            // console.log(doc._id)
                            event.preventDefault();

                            if (result) {
                                setSelectedDocuments((prevDocuments) =>
                                    prevDocuments.filter((document) => document._id !== doc._id)
                                );
                            } else {
                                if (selectedDocuments.length < 5){
                                    setSelectedDocuments((prevDocuments) => [
                                        ...prevDocuments,
                                        { _id: doc._id, name: doc.file_name, file_url: doc.file_url },
                                    ]);
                                }
                                
                            }
                            }}
                        >
                            <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                                {doc.file_name}
                            </span>
                            
                        </ShadeDD.DropdownMenuCheckboxItem>
                        <DeleteDialog doc={doc} setSelectedDocuments={setSelectedDocuments}/>
                    </div>
                
                ))}
                <ShadeDD.DropdownMenuSeparator />
                <ShadeDD.DropdownMenuLabel className="flex flex-row flex-nowrap items-center gap-2">
                    <Input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            // accept=".xlsx,.pdf,.docx"
                            multiple
                        />
                    <Button variant="outline"  className="mr-2" onClick={processDocuments} disabled={!fileInputRef.current || fileInputRef.current.files.length === 0}>
                        {fileProcessLoading ? <LoaderSpinner/> : <Upload className="w-5 h-5"/>}
                    </Button>   
                </ShadeDD.DropdownMenuLabel>
                    
            </ShadeDD.DropdownMenuContent>
            
        </ShadeDD.DropdownMenu>
    );
}


const DeleteDialog = ({doc, setSelectedDocuments}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        deleteUploadedDocs
    } = useContext(PartContext);

    const handleDelete = async () => {
        setIsOpen(false);
        setIsLoading(true);
        await deleteUploadedDocs(doc._id);
        setSelectedDocuments([]);
        setIsLoading(false);
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant="ghost" size="icon" className="px-2" onClick={() => setIsOpen(true)}>
                    {isLoading ? <LoaderSpinner/> : <Trash2 className="w-5 h-5" />}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Delete Document?</DialogTitle>
                    <DialogDescription>
                        Are you sure you want to delete this document?
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-2">
                    <span className=''>{doc.name}</span>
                </div>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={() => setIsOpen(false)}>
                            Close
                        </Button>
                    </DialogClose>
                    <Button
                        type="button"
                        className="bg-red-500 hover:bg-red-600"
                        onClick={handleDelete}
                        disabled={isLoading}
                    >
                        {isLoading ? <LoaderSpinner/> : "Delete Permanently"}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
};