import { bexHttpClient } from '../../clients';


/**
 * Service class for performing project operations.
 */
class ProjectService {

    /**
     * Fetches the sub organization projects.
     * @returns {Promise<Object>} The response from the server returns sub organization subscription.
     */
    async getSubOrgProjects(organizationId, subOrganizationId) {
        const response = await bexHttpClient.get(`db/projects/get-by-sub-organization?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}`)
        console.log('response: ', response);
        return response;
    }

    /**
     * add new project.
     * @param {Object} data - The new project data including name, organizationId, subOrganizationId.
     * @returns {Promise<Object>} The response from the server returns new project document from mongoDB.
     */
    async addProject(data) {
        const response = await bexHttpClient.post(`db/projects/add-project`, data);
        return response;
    }

    /**
     * add new collection.
     * @param {Object} data - The new collection data including name, organizationId, subOrganizationId and projectId.
     * @returns {Promise<Object>} The response from the server returns new collection document from mongoDB.
     */
    async addCollection(data) {
        const response = await bexHttpClient.post(`db/projects/add-collection`, data);
        return response;
    }
    /**
     * add new collection.
     * @param {Object} data - The updated collection data including organizationId, subOrganizationId, projectId, collectionId, name and assistants.
     * @returns {Promise<Object>} The response from the server returns updated collection document from mongoDB.
     */
    async updateCollection(data) {
        const response = await bexHttpClient.put(`db/projects/update-collection`, data);
        return response;
    }

    /**
     * delete collection.
     * @param {Object} data - Delete collection data including collectionId, organizationId, subOrganizationId and projectId.
     * @returns {Promise<Object>} The response from the server returns updated project document.
     */
    async deleteCollection(data) {
        const response = await bexHttpClient.delete(`db/projects/delete-collection`, {
            data: data
        });
        return response;
    }

}

const projectService = new ProjectService()
export { projectService }
