import React, { useContext, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from "../../../../components/ui/card";
import { Button } from "../../../../components/ui/button";
import {Combobox} from '../../../../components/ui/combobox';
import { RotateCcw, SendHorizontal, Copy, View } from 'lucide-react';
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Checkbox } from "../../../../components/ui/checkbox";
import { PartContext } from "../../context/PartContext";
// import sampleDocumentList from "../files/sampleRAGDocumentList.json";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
  } from "../../../../components/ui/dropdown-menu";
  import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../../../components/ui/tooltip";

export function RAGDemo() {

    const {
        selectedDocumentDemo, setSelectedDocumentDemo,
        queryRAG, addNewNotifcation,
        messagesDemo, setMessagesDemo,
        getDocList,
        user, selectedSubOrganization,
        selectedMethodDemo, setSelectedMethodDemo
    } = useContext(PartContext);

    
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const endOfMessagesRef = useRef(null);
    const [selectedMessages, setSelectedMessages] = useState([]);
    const [sampleDocumentList, setSampleDocumentList] = useState([]);
    const containerRef = useRef(null);

    
    const METHODS = [
        {_id: 1, name: "Method A", value: "watson_discovery"},
        {_id: 2, name: "Method B", value: "text_embedding"}
    ]

    const onSelectMethod = (value) => {
        console.log(value)
        setSelectedMethodDemo(METHODS.find(item => value._id === item._id).value)
    }


    const getFileExtension = (url) => {
        return url.split('.').pop().split(/\#|\?/)[0];
    };

    const ConstructEmbeddingResult = (documents) => {
        // console.log(documents)
        let str = "";
        documents.forEach((doc) => {
            let ext = getFileExtension(doc.metadata.name);
            let ppt = ext === "ppt" || ext === "pptx";
            str += "[Document]\n";
            str += ppt ? `[Page ${doc.metadata.slide_number}]\n` : "";
            // str += `${doc.metadata.name}\n`;
            str += `${doc.page_content}\n`;
            str += ppt ? `[Page ${doc.metadata.slide_number}]\n` : "";
            str += "[End]\n\n";
        })
        return str;
    }

    const handleSend = async () => {
        if (input.trim() === '') return;  // Prevent sending empty messages
        if (!selectedDocumentDemo || selectedDocumentDemo === null || (typeof selectedDocumentDemo === 'object' && Object.keys(selectedDocumentDemo).length === 0)){
            addNewNotifcation("Please select a document to query.", "danger");
            return;
        }

        const newMessage = { role: 'user', msg: input, _id: Date.now() };
        setMessagesDemo(prevMessages => [...prevMessages, newMessage]);
        setInput('');

        try {
            setLoading(true);
            let rag = "";
            const response = await queryRAG(input, true);
            rag += ConstructEmbeddingResult(response.documents);
            const ragMessage = response ? { role: 'rag', msg: rag.trim(), _id: Date.now() } : { role: 'system', msg: "I'm sorry that information is not found in the document.", _id: Date.now() };
            setMessagesDemo(prevMessages => [...prevMessages, ragMessage]);
        } catch (error) {
            console.error(error);
            setMessagesDemo(prevMessages => [
                ...prevMessages,
                { role: 'rag', msg: "An error occurred while fetching the information.", _id: Date.now() }
            ]);
        } finally {
            setLoading(false);
        }
            

    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          handleSend();
        }
    };

    const handleResetRAG = (e) => {
        setSelectedDocumentDemo("");
        setMessagesDemo([{role: "system", msg: "Hi. Please select a document to start querying.", _id: Date.now()}]);
        setSelectedMessages([]);
    };

    useEffect(() => {
        if (endOfMessagesRef.current) {
            // endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [messagesDemo]);

    const handleCheckboxChange = (e, msg) => {
        setSelectedMessages(prevSelectedMessages => {
            if (prevSelectedMessages.includes(msg)) {
                return prevSelectedMessages.filter(m => m !== msg);
            } else {
                return [...prevSelectedMessages, msg];
            }
        });
    };

    useEffect(() => {
        const fetchDocList = async () => {
            try {
                const filter = JSON.stringify({
                    assistant: 'ai-lab',
                    feature: "demo-prompt",
                    demo_doc: true
                })
                const res = await getDocList(user, selectedSubOrganization._id, filter, true);
                // console.log("DEMO DOCS: ", res)
                setSampleDocumentList(res);
            } catch (error) {
                console.error("Error fetching document list:", error);
            }
        };

        fetchDocList();
    }, [user, selectedSubOrganization]);

    const viewFile = () => {
        if (selectedDocumentDemo){
            window.open(selectedDocumentDemo.file_url, '_blank', 'noopener,noreferrer');
        }
        else{
            addNewNotifcation("Please select a document first.", "warning");
        }
        // alert("View");
    };


    return (
        <Card className="w-full flex flex-col justify-between h-fit min-h-[436px] max-h-[436px]">
            
            <CardHeader className="max-h-[76px] border-b-[1px] border-gray-200 px-6 py-3.5 grid grid-cols-2 gap-4">
                {/* <div className="flex flex-row flex-nowrap items-center gap-2">
                    <Label htmlFor="file" className="whitespace-nowrap text-md">RAG</Label>
                    <Combobox
                        defaultDisplayed='Select Document'
                        items={sampleDocumentList}
                        selectedValue={selectedDocumentDemo}
                        setter={setSelectedDocumentDemo}
                    />
                </div>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button size="icon" variant="outline" onClick={viewFile}>
                                <View className="h-5 w-5" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>View File</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider> */}
                <div className="grid grid-cols-1 gap-1.5">
                    <CardTitle className="text-sm">RAG</CardTitle>
                    <CardDescription className="text-xs">Try sample documents for different tasks.</CardDescription>
                </div>
                <div className="flex flex-row items-center gap-2 justify-end">
                    <Label className="">Samples:</Label>
                    <Combobox
                        defaultDisplayed='Documents'
                        items={sampleDocumentList}
                        selectedValue={selectedDocumentDemo}
                        setter={setSelectedDocumentDemo}
                    />
                    <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button size="icon" variant="outline" className="p-2" onClick={viewFile}>
                                <View className="min-h-5 min-w-5" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>View File</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
                </div>
            </CardHeader>

            <CardContent className="flex flex-col justify-end gap-4 p-0 flex-grow">
                    <div className="max-h-[296px] px-6 overflow-auto" ref={containerRef}>
                        {
                            messagesDemo.map((chat) => (
                                <div key={chat._id} className={`flex mb-4 mt-4 gap-2 items-start ${chat.role === "user" ? 'justify-end' : ''}`}>
                                    {chat.role === "rag" && <Checkbox className="mt-2" onCheckedChange={(e) => handleCheckboxChange(e, chat.msg)} checked={selectedMessages.includes(chat.msg)}/>}
                                    <div className={`${chat.role === "user" ? "bg-black text-white" : "bg-gray-100 text-gray-900"} rounded-lg p-3 max-w-[75%]`}>
                                        <p className="text-sm break-words whitespace-pre-line">{chat.msg}</p>
                                    </div>
                                </div>
                            ))
                        }
                        {loading && 
                            <div className="rag-typing mb-4">
                                <div className="dot bg-black"></div>
                                <div className="dot bg-black"></div>
                                <div className="dot bg-black"></div>
                            </div>
                        }
                        <div ref={endOfMessagesRef} />
                    </div>
            </CardContent>

            <CardFooter className=" h-16 max-h-16 border-t-[1px] border-gray-200 px-6 py-4 flex flex-row flex-nowrap gap-2">
                <Input
                    value={input}
                    onChange={(e)=>{setInput(e.target.value)}}
                    onKeyDown={handleKeyDown}
                    type="text"
                    placeholder="Send a query..."
                    className="col-span-1 h-10"
                />
                <Button variant="ghost" size="icon" className="px-2" onClick={handleSend}>
                    <SendHorizontal className="w-5 h-5" />
                </Button>
                <CopyDemo selectedMessages={selectedMessages}/>
                <Button variant="ghost" size="icon" className="px-2" onClick={handleResetRAG}>
                    <RotateCcw className="w-5 h-5" />
                </Button>
            </CardFooter>
        </Card>
    )
}

export function CopyDemo({selectedMessages}) {
    const {
        promptDemo, setPromptDemo,
        addNewNotifcation
    } = useContext(PartContext);

    const dataSelected = () => {
        if (selectedMessages.length > 0)
            return true;
        else
            return false;
    };


    const handleCopyToContext = (e) => {
        if (!dataSelected()){
            addNewNotifcation('Please select a queried information first.', 'danger');
            return;
        }

        const newMessages = selectedMessages.filter(msg => !promptDemo.context.includes(msg));
        if (newMessages.length > 0) {
            setPromptDemo({...promptDemo, context: (promptDemo.context ? promptDemo.context + '\n\n' : "") + newMessages.join('\n\n')});
        }
        addNewNotifcation('Copied to context successfully', 'success');
    }
    
    const handleCopyToInput = (e) => {
        if (!dataSelected()){
            addNewNotifcation('Please select a queried information first.', 'danger');
            return;
        }

        const newMessages = selectedMessages.filter(msg => !promptDemo.input_data.includes(msg));
        if (newMessages.length > 0) {
            setPromptDemo({...promptDemo, input_data: (promptDemo.input_data ? promptDemo.input_data + '\n\n' : "") + newMessages.join('\n\n')});
        }
        addNewNotifcation('Copied to input data successfully', 'success');
    }

    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="">
                <Copy className="w-5 h-5" />
            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          <DropdownMenuLabel>Copy Selected Data To</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={handleCopyToContext}>
              <span>Context</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleCopyToInput}>
              <span>Input Data</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    )
}