import { createContext, useState, useEffect, useContext } from "react";
import { subscriptionService, workflowService } from "api";
import { useParams } from "react-router-dom";
import subOrgSectionMenu from "data/subOrgSectionMenu";
import { projectService } from "api/services/BEX/project.service";
import { Context } from "./GlobalState";

export const SubOrgContext = createContext({});

export const SubOrganizationProvider = ({ children }) => {
    
    const [selectedSubOrganization, setSelectedSubOrganization] = useState({});
    const [loading, setLoading] = useState(true);
    const [workflow, setWorkflow] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [project, setProject] = useState(null);
    const [isSubOrganization, setIsSubOrganization] = useState(true);

    const [subOrgMenu, setSubOrgMenu] = useState(subOrgSectionMenu);
    const { oragID, subOragID } = useParams();

    // global context
    const { user } = useContext(Context);

    // Hooks
    useEffect(() => {
        if (user?.organization && user?.subOrganizations?.length > 0) {
        const storedSubOrg = localStorage.getItem("selectedSubOrg");
        const isMember = user?.subOrganizations.find(
            (i) => i._id === storedSubOrg
        );
        if (storedSubOrg && isMember) {
            setSelectedSubOrganization(isMember);
            localStorage.setItem("selectedSubOrg", isMember._id);
        } else {
            setSelectedSubOrganization(user?.subOrganizations[0]);
            localStorage.setItem(
            "selectedSubOrg",
            user?.subOrganizations[0]._id
            );
        }
        } else {
            setIsSubOrganization(false);
        }
        fetchSubOrgData();
    }, [user]);

    // Actions
    const actions = (action) => {
        const { type, payload } = action;

        switch (type) {
        case "SET_LOADING":
            return setLoading(payload);
        case "SET_WORKFLOW":
            return setWorkflow(payload);
        case "SET_SUBSCRIPTION":
            return setSubscription(payload);
        case "SET_PROJECT":
            return setProject(payload);
        case "SET_IS_SUB_ORGANIZATION":
            return setIsSubOrganization(payload);
        case "SET_SELECTED_SUB_ORGANIZATION":
            return setSelectedSubOrganization(payload);
        default:
            return loading;
        }
    };

    const fetchSubOrgData = async () => {
        try {
            const workflowResponse = await workflowService.getSubOrgWorkflow(
            oragID,
            subOragID
            );
            setWorkflow(workflowResponse.data);
            setSubOrgMenu((prev) => ({ ...prev, name: workflowResponse.data.name }));

            const subscriptionResponse =
            await subscriptionService.getSubOrgSubscription(subOragID);
            setSubscription(subscriptionResponse.data);

            const projectsResponse = await projectService.getSubOrgProjects(
            oragID,
            subOragID
            );
            setProject(projectsResponse.data[0]);
        } catch (error) {
            console.log("error: ", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SubOrgContext.Provider
        value={{
            actions,
            loading,
            workflow,
            subscription,
            subOrgMenu,
            project,
            selectedSubOrganization,
            isSubOrganization,
        }}
        >
            {children}
        </SubOrgContext.Provider>
    );
};
