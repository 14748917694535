import React, { useContext, useState, useEffect, useRef } from 'react';
import { Button } from "components/ui/button";
import { FileText, Trash2, Upload } from 'lucide-react';
import * as ShadeDD from "components/ui/dropdown-menu";
import { LoaderSpinner } from 'components/LoaderSpinner';
import { Input } from 'components/ui/input';
import { v4 as uuidv4 } from 'uuid';


export const SampleList = ({inputMessage, setInputMessage, setLanguage}) => {
    const [isOpen, setIsOpen] = useState(false);
    const SAMPLES = [
        
        {_id: uuidv4(), name: "OOP (Python)", value: "sample_4", language: "python", query: 
            "Write a Python function called getAdults that takes an array of objects representing people, each with name and age properties. The function should return an array of names of people who are 18 years old or older. Define people array of objects and print the returned people's name"},
        {_id: uuidv4(), name: "Form Validation (Python)", value: "sample_5", language: "python", query: 
            "Generate a complete Python code that validates username and password. Ensure that the username is not empty and the password is at least 8 characters long. Display appropriate error messages if the validation fails. Test the function with sample data"},
        {_id: uuidv4(), name: "Read Data from API (Python)", value: "sample_6", language: "python", query: 
            "Generate Python code to fetch data from an API endpoint (https://135717ae-f5ef-492d-80c9-6dc9c4c68fd7.mock.pstmn.io/current?query=New Delhi). Response will be in JSON format, extract following data from the response: location[name], location[country], current[temperature], current[observation_time] and display in the console"},
        {_id: uuidv4(), name: "Stock Price Analysis (Python)", value: "sample_7", language: "python", query: 
`Write a Python script that analyzes sample stock price data to calculate the simple moving average (SMA) over a specified window (e.g., 3 days). Use the \`pandas\` library to handle the data and perform calculations. The script should include sample stock price data and print the stock data along with the calculated SMA.
`},
        {_id: uuidv4(), name: "BMI Calculator (Python)", value: "sample_8", language: "python", query: 
            "Write a Python program that calculates the Body Mass Index (BMI) from defined user dictionary of weight and height and prints whether they are underweight, normal weight, overweight, or obese based on the BMI value."},
        {_id: uuidv4(), name: "Bank Account OOP (Python)", value: "sample_8", language: "python", query: 
`Write a Python class called BankAccount that simulates a bank account. The class should support the following operations:

Deposit: Add a specified amount to the account balance. If the deposit amount is negative or zero, raise a ValueError.
Withdrawal: Subtract a specified amount from the account balance. If the withdrawal amount is greater than the current balance, raise a ValueError indicating insufficient funds. If the withdrawal amount is negative or zero, raise a ValueError.
Balance Check: Return the current balance of the account.
Transaction History: Maintain a record of all deposits and withdrawals. Each transaction should be stored with a timestamp, type of transaction (deposit/withdrawal), and amount.
Overdraft Protection: Allow an optional overdraft limit that permits withdrawals that exceed the balance up to a certain limit. If overdraft protection is enabled, ensure that withdrawals do not exceed the combined balance and overdraft limit.
Advanced Requirements:
Account Statement: Add a method get_statement that returns a list of all transactions in chronological order, formatted as Date | Type | Amount | Balance After Transaction.
Account Locking: Add functionality to lock the account after three consecutive failed withdrawal attempts (due to insufficient funds). Provide a method to unlock the account.
Interest Calculation: Add a method apply_interest that increases the account balance by a specified annual interest rate, applied monthly.
Initialize an Account then perform all the actions`},
    ];

    const OnSampleSelected = (sample) => {
        // alert(sample.name);
        setInputMessage(sample.query);
        setLanguage(sample.language);
        setIsOpen(false);
    };

    return(
        <ShadeDD.DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
            <ShadeDD.DropdownMenuTrigger asChild>
                <Button variant="outline" className="px-4">
                    {/* <FileText className="w-5 h-5" /> */}
                    Samples
                </Button>
            </ShadeDD.DropdownMenuTrigger>
            <ShadeDD.DropdownMenuContent align="end" side="bottom" className="w-[300px]">
                {/* <ShadeDD.DropdownMenuLabel>
                    {uploadedDocs.length === 0 ? "No documents" : "Documents"}
                </ShadeDD.DropdownMenuLabel> */}
                <ShadeDD.DropdownMenuGroup>
                    {SAMPLES.map((s) => (
                        <div
                            key={s._id}
                        >
                            <Button 
                                variant="ghost"
                                className="w-full whitespace-normal break-words"
                                onClick={() => OnSampleSelected(s)}>
                                {s.name}
                            </Button>    
                        </div>
                    
                    ))}
                </ShadeDD.DropdownMenuGroup>
                
            </ShadeDD.DropdownMenuContent>
        </ShadeDD.DropdownMenu>
    );
}


