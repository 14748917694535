import { useState,useEffect } from "react";
import { channelService } from '../api';

export function useChannels(oragID, subOragID, channelType=null) {
    const [channels, setChannels] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        setError(null)
        channelService.getChannels(oragID, subOragID)
        .then((res) => {
            console.log('res: ', res);
            
            if (!channelType) {
                setChannels(res.data)
            } else {
                // filter only selected channel type
                setChannels(res.data.filter((item) => item.type === channelType))
            }
        }).catch((error) => {
            console.log('error: ', error);
            setError(error.message)
        }).finally(() => {
            setLoading(false)
        })
    }, []) 
    
  
    return { channels, setChannels, loading, error };
}
