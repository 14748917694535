import { httpRequestO } from "utils/httpsRequest";
import axios from 'axios';

// System Prompt ----------------------------------------------------------------
const sys = "You are a helpful assistant. You follow the constraints:"+
`
1. All code should be generated within the "\`\`\`Code\`\`\`" format to maintain proper structure and readability.
2. Avoid using code that requires user inputs. Instead, create and use sample values to demonstrate functionality.
3. Ensure the code sticks to text outputs, avoiding graphical interfaces or visualizations unless explicitly requested.
4. The code should follow proper syntax and structure, adhering to Python's best practices and conventions.
5. Use accurate and descriptive variable names, objects, and dictionaries that clearly reflect their purpose.
6. Maintain correct and consistent indentation throughout the code to ensure clarity and prevent syntax errors.
7. Limit responses to the code only, without additional explanations or commentary unless explicitly requested.
8. Include basic error handling to manage potential runtime issues gracefully, such as handling network errors or invalid data.
9. Prioritize readability by including comments where necessary to explain complex logic or decisions within the code.
10. Ensure all outputs are formatted clearly and consistently, with labels or context to make the results understandable.
11. Use descriptive and meaningful names for variables, functions, and classes to make the code self-explanatory.
12. Write optimized code, avoiding unnecessary operations, redundant calculations, or inefficient loops.
13. Ensure the code is compatible with the latest version of Python or the specified version if mentioned in the prompt.
14. Properly manage resources, ensuring that any opened files, network connections, or external resources are closed or cleaned up appropriately.
`;
// 15. Do not use the if \`__name__ == "__main__":\` construct in the code.
// 16. Ensure that the code includes functionality to display output, so results can be observed without additional code modifications.
// 17. Prohibit using files for source or output. All data handling should be done using in-memory structures or other non-file methods.
// 18. Avoid using recursive functions in the code.
// 19. Do not use global variables, modules, and other functions inside functions; instead, pass them as arguments.
// 20. Lessen the use of imports in the code if possible.
// 21. Lessen the use of classes in the code if possible.


// Model Parameters ----------------------------------------------------
const parameters = {
    mode: "greedy",
    method: "greedy",
    max_tokens: 16384,
    max_new_tokens: 2000,
    stop_sequences: ["<|endoftext|>", "<|endofcode|>"],
    repetition_penalty: 1.2
}

// Format Recent Conversation ------------------------------------------------
// Format recent convo to a string following a prompt structure
const FormatConversation = (convo) => {
    return convo.reduce((acc, { role, message }, index) => {
        if (index % 2 === 0) {
            acc.push(`<s>\n[INST]\n${message}\n[/INST]`);
        } else {
            acc[acc.length - 1] += `\n${message}\n</s>`;
        }
        return acc;
    }, []).join("\n\n");
};

// Prompt Constructor ----------------------------------------------------------
// Construct the prompt to a string following a prompt structure
const ConstructPrompt = (query, convo) => {
    const formattedConversation = convo.length > 0 ? FormatConversation(convo) : "";
    return `${formattedConversation}<s>\n[INST]\n${sys}\n${query}\n[/INST]`;
};

// Send LLM prompt -------------------------------------------------------------
// Predict using user prompt
const Predict = async (modelId, parameters, prompt) => {
    try {
        const result = await httpRequestO.post('/projecto/api/v1/model/foundationModel/experiment?mode=freeMode', {
            modelId: modelId,
            parameters: parameters,
            prompt: prompt,
            query: ""
        }, {
            headers: { 'Content-Type': 'application/json' }
        });
        return result.data.prediction;
    } catch (error) {
        console.error("API call failed:", error);
        throw error;  // Throw error to handle at a higher level if needed
    }
};


// Main Prompting function
export const Prompt = async (query, convo) => {
    const prompt = ConstructPrompt(query, convo);
    console.log("Prompt:", prompt);
    console.log("Parameters:", parameters);
    
    return Predict("mistralai/mixtral-8x7b-instruct-v01", parameters, prompt);
};



// =================================================================
// Code Execution

const Execute = async (code, language) => {
    try {
        const response = await axios.post(
            'http://localhost:8001/code-exe/execute',
            { code, language },
            { headers: { 'Content-Type': 'application/json' } }
        );
        const { result, error } = response.data;
        return result || error || 'Unexpected response format';
    } catch (error) {
        console.error('Error executing code:', error);
        return 'Error executing code.';
    }
};

export const RunCode = async (editorCode, language) => {
    return Execute(editorCode, language);
};