import React, { useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "components/ui/dialog";
import { Button } from "components/ui/button";
// import step1 from "../../files/Step1.png";
// import step2 from "../../files/Step2.png";
// import step3 from "../../files/Step3.png";
// import step4 from "../../files/Step4.png";


const InstructionDialog = () => {
    const [open, setOpen] = useState(false);
    const handleOnOpen = () => {
        setOpen(true);
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="ghost" className="py-0 text-sm" onClick={handleOnOpen}>
                    How to Use
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px] max-h-[600px] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>How to use the Code Sandbox IDE</DialogTitle>
                    <DialogDescription>
                        To run your code, follow these steps:
                    </DialogDescription>
                </DialogHeader>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-6">
                    <div className="flex flex-col items-left p-4 bg-white shadow-md rounded-lg justify-between">
                        <p className="font-bold text-xl mb-2 text-black-600">Step 1: Open Folder</p>
                        {/* <img src={step1} alt="Step 1" className="w-full h-auto mb-2" /> */}
                        <p className="text-xs text-gray-600">Click "Open Folder" in the Explorer or press "CTRL + K + O".</p>
                    </div>
                    <div className="flex flex-col items-center p-4 bg-white shadow-md rounded-lg justify-between">
                        <p className="font-bold text-xl mb-2 text-black-600">Step 2: Open Workspace Folder</p>
                        {/* <img src={step2} alt="Step 2" className="w-full h-auto mb-2" /> */}
                        <p className="text-xs text-gray-600">Navigate to "/home/workspace/user1/" or paste this path in the field and click "OK".</p>
                    </div>
                    <div className="flex flex-col items-center p-4 bg-white shadow-md rounded-lg justify-between">
                        <p className="font-bold text-xl mb-2 text-black-600">Step 3: Create Your Own Folder & File</p>
                        {/* <img src={step3} alt="Step 3" className="w-full h-auto mb-2" /> */}
                        <p className="text-xs text-gray-600">Create your folder and file by clicking the "Folder+" icon and "File+" icon inside your created folder.</p>
                    </div>
                    <div className="flex flex-col items-center p-4 bg-white shadow-md rounded-lg justify-between">
                        <p className="font-bold text-xl mb-2 text-black-600">Step 4: Run Your Code</p>
                        {/* <img src={step4} alt="Step 4" className="w-full h-auto mb-2" /> */}
                        <p className="text-xs text-gray-600">Run your code by clicking the "Run" button at the top right.</p>
                    </div>
                </div>
                <DialogFooter>
                    <Button variant="default" onClick={()=>setOpen(false)}>OK</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export default InstructionDialog;
