import React, { useContext, useEffect, useState } from "react"
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "components/ui/card"
import { Button } from "components/ui/button"
import usePackages from "hooks/usePackages"
import { AppLoader } from "components/LoaderSpinner"
import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs"
import { loadStripe } from '@stripe/stripe-js'
import { server } from "utils/httpsRequest"
import { useParams, useSearchParams } from "react-router-dom"
import { Elements } from '@stripe/react-stripe-js'
import { Context } from "context/GlobalState"
import SubscriptionDetails from "./SubscriptionDetails"
import { SubOrgContext } from "context/SubOrganizationContext"

const stripePromise = loadStripe('pk_test_51MJbjwIqfn3WG6ZB9P2Q1empzz1TPD2JiNrpK6chqSpgxU0c6hb72lYdrNtdRxiVXrf0bXvTQ06VSY3zF0LP5reY0004gUuwjD')

export default function SubOrganizationPlan() {
    const { packages, loading, error } = usePackages()
    const [searchParams] = useSearchParams()
    const { addNewNotifcation, selectedSubOrganization } = useContext(Context)
    const { subscription } = useContext(SubOrgContext)
    const [pricingType, setPricingType] = useState('monthly')

    useEffect(() => {
        if (searchParams.get("canceled")) {
            addNewNotifcation('Failed to subscribe to the plan', 'warning')
        }
    }, [searchParams, addNewNotifcation])

    if (subscription) {
        return <SubscriptionDetails subscription={subscription.stripeSubscription} />
    }

    if (loading) {
        return <div className='flex flex-grow'><AppLoader size={50} /></div>
    }

    if (error) {
        return <div className='flex flex-grow'><div>Error: {error}</div></div>
    }

    return (
        <Elements stripe={stripePromise}>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold text-center mb-8">Choose Your Plan</h1>
                <Tabs defaultValue="monthly" className="w-full mb-8">
                    <TabsList className="grid w-full max-w-md mx-auto grid-cols-2">
                        <TabsTrigger value="monthly" onClick={() => setPricingType('monthly')}>
                            Monthly
                        </TabsTrigger>
                        <TabsTrigger value="annual" onClick={() => setPricingType('yearly')}>
                            Annual
                        </TabsTrigger>
                    </TabsList>
                </Tabs>
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {packages.map((item) => (
                        <PackageCard {...item} key={item.id} pricingType={pricingType} isTrialUsed={selectedSubOrganization?.isTrialUsed} />
                    ))}
                </div>
            </div>
        </Elements>
    )
}

const PackageCard = ({ name, description, prices, hasTrial, isTrialUsed, pricingType }) => {

    const { selectedSubOrganization } = useContext(SubOrgContext)
    const { oragID, subOragID } = useParams()

    const isMainSuborg = selectedSubOrganization?.isMain // Only the main suborg can start a trial

    const price = prices[pricingType]?.amount || 0
    const priceId = prices[pricingType]?.priceId

    const createCheckoutLink = (asTrial) => {
        return `${server}/db/subscriptions/create-checkout-session?organizationId=${oragID}&subOrganizationId=${subOragID}&priceId=${priceId}&isTrial=${asTrial}`
    }

    return (
        <Card className="flex flex-col justify-between">
            <CardHeader>
                <CardTitle>{name}</CardTitle>
                {/* <CardDescription>{description}</CardDescription> */}
            </CardHeader>
            <CardContent>
                {/* 
                    If the price is 0, then it's a  'Contact sales for details.'
                */}
                {price === 0 && (
                    <div className="text-3xl font-bold mb-4">
                        Call Sales
                    </div>
                )}
                {price > 0 && (
                    <div className="text-3xl font-bold mb-4">
                        ${price} {pricingType === 'monthly' ? '/mo' : '/yr'}
                    </div>
                )}
                <ul className="grid gap-2 text-sm text-muted-foreground">
                    {description?.split('.')?.map((it) => (
                        // TIP: Make sure each period ends with a dot (.) i.e. "Unlimited users. Unlimited projects."
                        <li key={it}>
                            <CheckIcon className="mr-2 inline-block h-4 w-4 fill-primary" />
                            {it}
                        </li>
                    ))}
                </ul>
            </CardContent>
            <div className='mt-auto w-full flex flex-col px-6 py-4 space-y-4'>
                <form action={createCheckoutLink(false)} method="POST">
                    {/* if the price is 0 disable */}
                    <Button type="submit" className="w-full" disabled={price === 0}>
                        Choose {name}
                    </Button>
                </form>
                {hasTrial && !isTrialUsed && isMainSuborg && (
                    <form action={createCheckoutLink(true)} method="POST">
                        <Button type="submit" className="w-full" variant="secondary">
                            Start Free Trial
                        </Button>
                    </form>
                )}
            </div>
        </Card>
    )
}

function CheckIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M20 6 9 17l-5-5" />
        </svg>
    )
}