import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { PlusIcon, XIcon } from 'lucide-react'
import { containsKeyValue } from 'pages/EmailHandling/Document'
import React, { useEffect, useState } from 'react'

export default function WorkflowMetadata({ metadata, setMetadata }) {
    
    const [isEmpty, setIsEmpty] = useState(false)

    useEffect(() => {
        setIsEmpty(containsKeyValue(metadata, 'value') || containsKeyValue(metadata, 'key'))
    }, [metadata])

    const addMetadata = () => {
        setMetadata([...metadata, { key: "", value: "" }])
    }

    const removeMetadata = (index) => {
        const updatedMetadata = [...metadata]
        updatedMetadata.splice(index, 1)
        setMetadata(updatedMetadata)
    }

    const handleMetadataChange = (index, field, value) => {
        const updatedMetadata = [...metadata]
        updatedMetadata[index][field] = value
        setMetadata(updatedMetadata)
    }

    return (
        <div>
            <div className='flex flex-col gap-2'>
                {metadata.map((item, index) => (
                    <div key={index} className="grid grid-cols-[1fr_1fr_auto] gap-2">
                        <Input
                            placeholder="Key"
                            value={item.key}
                            onChange={(e) => handleMetadataChange(index, "key", e.target.value)}
                        />
                        <Input
                            placeholder="Value"
                            value={item.value}
                            onChange={(e) => handleMetadataChange(index, "value", e.target.value)}
                        />
                        <Button type="button" variant="ghost" size="icon" onClick={() => removeMetadata(index)}>
                            <XIcon className="h-4 w-4" />
                            <span className="sr-only">Remove metadata</span>
                        </Button>
                    </div>
                ))}
            </div>
            <Button disabled={isEmpty} type="button" className='mt-2' variant="ghost" onClick={addMetadata}>
                <PlusIcon className="h-4 w-4 mr-2" />
                Add Metadata
            </Button>
        </div>
    )
}
