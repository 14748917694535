import React, { useContext, useEffect, useState } from 'react'
import { Button } from "../../components/ui/button"
import { Textarea } from "../../components/ui/textarea"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'components/ui/card'
import emailSamples from './EmailDemo/emailSamples'
import { useParams } from 'react-router-dom'
import { Context } from '../../context/GlobalState'
import { httpRequestO, httpRequestOSetup, serverOSetup } from '../../utils/httpsRequest'
import { areObjectsEqual, convertObjectToArray } from '../../utils/arrayUtils'
import { Input } from '../../components/ui/input'
import { Label } from '../../components/ui/label'
import { LoaderModal } from '../../components/LoaderModal'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { ragService } from 'api/services/PROJECT-O'
import { arrayToObject } from './Document'
import { LoaderSpinner } from 'components/LoaderSpinner'
import { TrashIcon } from 'lucide-react'

export default function EmailHandlingDemo() {

    const [emailSample, setEmailSample] = useState('')
    const [show, setShow] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [emailInbox, setEmailInbox] = useState(false)
    const [isPredefined, setIsPredefined] = useState(true)
    const [uploadedDocs, setUploadedDocs] = useState([])
    const [resData, setResData] = useState()
    const [opcodeResponse, setOpcodeResponse] = useState({})

    const { oragID, subOragID } = useParams()
    const { user, addNewNotifcation } = useContext(Context)


    const handleOpCodeClick = async () => {

        if (!emailSample) {
            return addNewNotifcation('Please choose email sample', 'warning')
        } else if (!isPredefined && uploadedDocs.length <= 0) {
            return addNewNotifcation('Please add at least one file to process email', 'warning')
        }

        const encodedEmailTitle = encodeURIComponent(emailSample);

        try {
            setBtnLoading(true);
            const emailSampleObj = {
                "user_input": encodedEmailTitle,
                "request_id": `${oragID}-${subOragID}-${user._id}`,
                "callback_api": `${serverOSetup}/callback/email-callback`,
                "use_predefined": isPredefined
            }

            const res = await httpRequestO.post(`/projecto/api/v1/cpie/mini`, emailSampleObj)
            console.log("res: ", res.data);

            if (res.data?.results) {
                const emailSampleRes = {
                    details: res.data?.results.details,
                    response: res.data?.results.response,
                }
                setResData(emailSampleRes);
                setShow(true);
            }
            
        } catch(err) {
            console.log("error: ", err);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
        } finally {
            setEmailInbox(false)
            setBtnLoading(false)
        }
    };

    const handleChooseEmailSample = (item) => {
        setEmailSample(item.email)
        setShow(false)
    }


    // check for email callback
    useEffect(() => {
        let interval;

        const checkApiResponse = async () => {
            
            try {
                const emailObj = {
                    organization: oragID,
                    subOrganization: subOragID,
                    userId: user._id,
                }

                const res = await httpRequestOSetup.post('callback/get-email-inbox', emailObj)
                console.log('res: ', res);
                if (res.data?.inboxe?.processed) {
                    const emailSampleRes = {
                        details: res.data?.inboxe.details,
                        response: res.data?.inboxe.response,
                    }
                    setResData(emailSampleRes);
                    setEmailSample(res.data?.inboxe.emailContent || '')
                    setShow(true);
                    setEmailInbox(false)
                    clearInterval(interval);
                    setBtnLoading(false)
                }
            } catch (error) {
                console.error('Error checking API response:', error);
            }
        };
    
        if (emailInbox) {
          interval = setInterval(checkApiResponse, 4000);
        }
    
        return () => clearInterval(interval);
    }, [emailInbox])

    useEffect(() => {
        setOpcodeResponse(resData)
    }, [resData])

    return (
        <div>
            <div className='py-4 h-auto'>
                <Card className='relative h-auto'>
                    {(emailInbox || btnLoading) && <LoaderModal />}

                    <CardHeader>
                        <CardTitle>Test AI Email Assistants</CardTitle>
                        <CardDescription>Select an email, modify the content, and see the AI's response.</CardDescription>
                    </CardHeader>

                    <CardContent className="flex flex-col h-auto gap-4">

                        <EmailHandlingUpload uploadedDocs={uploadedDocs} setUploadedDocs={setUploadedDocs} setIsPredefined={setIsPredefined} />

                        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
                            {emailSamples.map(item => (
                                <Button key={item.opcode} variant="outline" onClick={handleChooseEmailSample.bind(this, item)}>
                                    <span className="truncate">{item.opcode}</span>
                                </Button>
                            ))}
                        </div>
            
                        <div className="grid gap-4">
                            <Textarea value={emailSample} onChange={(e) => setEmailSample(e.target.value)} placeholder="Modify the email content here..." className="min-h-[200px]" />
                        </div>

                        {show && (
                            <div>
                                {opcodeResponse?.details?.map((response, index) => (
                                    <OpCodeResponse setEmailInbox={setEmailInbox} 
                                    emailSample={emailSample} setOpcodeResponse={setOpcodeResponse} opcodeResponse={opcodeResponse} resData={resData} response={response} index={index}  />
                                ))}
                                <div className="flex flex-col gap-2">
                                    <div className="font-medium">AI's Response:</div>
                                    <div className="prose text-muted-foreground">
                                        <Textarea 
                                        rows={15}
                                        value={opcodeResponse?.response}
                                        disabled 
                                        className="min-h-[200px] !text-black resize-none" />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="grid gap-4">
                            <Button onClick={handleOpCodeClick} disabled={!emailSample || btnLoading} className="w-full">Process</Button>
                        </div>

                    </CardContent>
                    
                </Card>
            </div>
        </div>

    )
}

const OpCodeResponse = ({ response, emailSample, opcodeResponse, setOpcodeResponse, resData, setEmailInbox, index }) => {

    const { oragID, subOragID } = useParams()
    const { user, addNewNotifcation } = useContext(Context)
    const [regenerateLoading, setRegenerateLoading ] = useState(false)


    const handleRegenerateResponse = async () => {

        setRegenerateLoading(true)
        const regenerateObj = {
          user_input: emailSample,
          details: opcodeResponse.details,
          "request_id": `${oragID}-${subOragID}-${user._id}`,
          "callback_api": `${serverOSetup}/callback/email-callback`
        }
        console.log('regenerateObj: ', regenerateObj);
    
        try {
            const res = await httpRequestO.post('/projecto/api/v1/cpie/regeneration', regenerateObj)
            console.log('res: ', res);
            
            const callbackObj = {
                organization: oragID,
                subOrganization: subOragID,
                userId: user._id,
                emailContent: emailSample
            }
            const inboxResponse = await httpRequestOSetup.post('callback/add-email-inbox', callbackObj)
            console.log('inboxResponse: ', inboxResponse);
            setEmailInbox(true)
            addNewNotifcation('Regeneration sent successfully. Please wait for response generation', 'success')
        } catch(error) {
            addNewNotifcation('Something went wrong', 'danger')
            console.log('error: ', error);
        } finally {
            setRegenerateLoading(false)
        }
    
    }
    
    const handleRagChange = (value, type, i) => {
    
        setOpcodeResponse(prev => {
            const updatedArray = prev.details.map((obj, index) => {
                if (index === i) {
                    return { ...obj, [type]: value }; 
                }
                return obj;
            });
    
            return { ...prev, details: updatedArray };
        });
    }

    return (
        <div className="flex flex-col w-full" key={index}>
            <div className="flex flex-col my-2">
                <div className="mb-2">
                    <Label>  Intent Classification {index + 1}</Label>
                </div>
                <Input
                    className='text-black'
                    value={response.opcode}
                    onChange={() => {}}
                    disabled />
                <div className="mt-4">
                    <Label>
                        Data extracted from email based on intent classification 
                    </Label>
                </div>
                <div className="flex flex-col mt-4">
                    <div className="flex flex-col w-full gap-3">
                        {convertObjectToArray(response.entities).map((row) => (
                            <div key={row.field} className="flex items-center gap-3 justify-between">
                                <span className='w-[200px] capitalize'>
                                    {" "}
                                    {row.field?.replaceAll("_", " ")}:{" "}
                                </span>
                                <Input
                                    className='text-black'
                                    name={row.field}
                                    value={row.fieldType || 'None'}
                                    onChange={() => {}}
                                    disabled />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col gap-4 mt-4">
                    <Label className='font-bold text-lg'>Data extracted from hotel policy</Label>
                    <div className="flex flex-col gap-3">
                        <Label>Structured RAG</Label>
                        <Textarea onChange={(e) => handleRagChange(e.target.value, 'structured_rag', index)} 
                        value={response?.structured_rag || ''} className='min-h-[100px] max-h-[300px]' disabled={!response?.structured_rag} name="rag" />
                    </div>
                    <div className="flex flex-col gap-3">
                        <Label>Unstructured RAG</Label>
                        <Textarea onChange={(e) => handleRagChange(e.target.value, 'unstructured_rag', index)} 
                        value={response?.unstructured_rag || ''} className='min-h-[100px] max-h-[300px]' disabled={!response?.unstructured_rag} name="rag"/>
                    </div>
                </div>

                <div className="flex mt-3 justify-end">
                    <Button disabled={regenerateLoading || areObjectsEqual(resData, opcodeResponse)} onClick={handleRegenerateResponse}>
                        Regenerate Response
                    </Button>
                </div>
            </div>
        </div>
    )
}


const EmailHandlingUpload = ({ uploadedDocs, setUploadedDocs, setIsPredefined }) => {

    const [newDocs, setNewDocs] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const [delBtnLoading, setDelBtnLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    const { oragID } = useParams()
    const { user, addNewNotifcation } = useContext(Context)

    const handleTabChange = (tab) => {
        if (tab === 'upload-document') {
            setIsPredefined(false)
        } else {
            setIsPredefined(true)
        }
    };

    const handleSetUploadFiles = (e) => {
        setNewDocs(e.target.files)
    }

    const handleDelete = (_id) => {
        setDelBtnLoading(true)
        ragService.deleteDocument(oragID, process.env.REACT_APP_DEMO_MODE, _id, `${oragID}-bex-demo-mode`)
        .then(() => {
            setUploadedDocs(prev => prev.filter((obj) => obj._id !== _id));
            addNewNotifcation('Document deleted successfully', 'success');
        }).catch((err) => {
            console.error('Error deleting document:', err);
        }).finally(() => {
            setDelBtnLoading(false)
        });
    }

    useEffect(() => {
        getDocs()
        .then((res) => {
            console.log('res: ', res);
        })
    }, [])


    const getDocs = async () => {
        try {
            setLoading(true)
            const res = await ragService.listDocuments(oragID, process.env.REACT_APP_COMMON_SUB_ORGANIZATION_ID, {'assistant':`email-handling`, 'userId': user._id})

            console.log('res: ', res);
            setUploadedDocs(res.data)
            return res.data
        } catch(err) {
            console.log('err: ',err);
        } finally {
            setLoading(false)
        }

    }

    const handleUploadFiles = () => {
        if (!newDocs || newDocs?.length <= 0) {
            return addNewNotifcation('Document could not be uploaded. Document is required. Please provide a document before proceeding.', 'danger')
        }

        setBtnLoading(true)
        const formData = new FormData();

        // add user id as metadata
        const metadataQueries = [
            {key: 'userId', value: user._id}, 
            {key: 'bucket_name', value: `${oragID}-bex-demo-mode`},
            {key: 'assistant', value: `email-handling`},
        ]

        formData.append(`organization_id`, oragID);
        formData.append(`sub_organization_id`, process.env.REACT_APP_DEMO_MODE);
        // formData.append(`files`, newDocs);
        for (let i = 0; i < newDocs.length; i++) {
            formData.append('files', newDocs[i]);
        }
        formData.append(`metadata`, JSON.stringify(arrayToObject(metadataQueries)));
        formData.append('bucket_name', `${oragID}-bex-demo-mode`);

        ragService.processFile(formData)
        .then(async (res) => {
            console.log(res.data);
            await getDocs()
            addNewNotifcation('Document added successfully', 'success')
            setNewDocs([])
        }).catch((err) => {
            console.log('err: ', err);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    return (
        <div>
            <Tabs defaultValue="use-predefined-document" className="mt-6 overflow-auto" onValueChange={handleTabChange}>
                <TabsList>
                    <TabsTrigger value="use-predefined-document" >Use Predefined Document</TabsTrigger>
                    <TabsTrigger value="upload-document" >Upload Document</TabsTrigger>
                </TabsList>

                <TabsContent value="upload-document">
                    <Card className="w-[100%]">
                        <CardHeader>
                            <CardTitle>Upload Documents</CardTitle>
                            <CardDescription>Upload files by clicking the "Choose files". Supported files are .txt, .docx, .xlsx.</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div>
                                <label>
                                    <Input
                                    multiple
                                    type="file"
                                    className="bg-background block mt-1"
                                    onChange={handleSetUploadFiles}
                                />
                                </label>
                                <div className='w-full flex justify-end mt-3'>
                                    <Button
                                    disabled={btnLoading || (!newDocs || newDocs?.length <= 0)}
                                    onClick={handleUploadFiles}
                                    className="w-fit hover:cursor-pointer right-0">
                                        Upload
                                    </Button>
                                </div>
                            </div>

                            <div className="flex-grow overflow-auto">
                                <Label> Files </Label>
                                {loading ? <LoaderSpinner /> : uploadedDocs.map((doc) => (
                                    <div key={doc._id} className="flex-grow flex justify-between items-center mt-4 p-2 border rounded">
                                        <div>
                                            <a target='_blank' href={doc.file_url || doc.url}>
                                                <span>{doc.file_name}</span>
                                            </a>
                                        </div>
                                        <Button variant='ghost' className='text-red-500' disabled={delBtnLoading} onClick={() => handleDelete(doc._id)}>
                                            <TrashIcon size={18} />
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                        <CardFooter className="pt-1 flex gap-2 flex-row-reverse">

                        </CardFooter>
                    </Card>
                </TabsContent>
            </Tabs>

        </div>
    )
}