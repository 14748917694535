import { bexHttpClient } from '../../clients';

/**
 * Service class for performing subscription operations.
 */
class SubscriptionService {

    /**
     * Fetches the sub organization subscription.
     * @returns {Promise<Object>} The response from the server returns sub organization subscription.
     */
    async getSubOrgSubscription(subOrganizationId) {
        const response = await bexHttpClient.get(`/db/subscriptions/get-subscriptions-by-sub-organization/${subOrganizationId}`);
        return response;
    }

    /**
     * Fetches the organization subscriptions.
     * @returns {Promise<any[]>} The response from the server returns organization subscriptions.
     */
    async getOrgSubscriptions(organizationId) {
        const response = await bexHttpClient.get(`/db/subscriptions/get-subscriptions-by-organization/${organizationId}`);
        return response;
    }

    /**
     * Creates a checkout session for a subscription.
     * @param {string} priceId - The price ID for the subscription.
     * @param {boolean} isTrial - Whether the subscription is a trial.
     * @returns {Promise<Object>} The response from the server returns the checkout session.
     */
    async createCheckoutSession(organizationId, subOrganizationId, priceId, isTrial) {
        const response = await bexHttpClient.post(`/db/subscriptions/create-checkout-session`, {}, {
            params: {
                organizationId: organizationId,
                subOrganizationId: subOrganizationId,
                priceId,
                isTrial,
            }
        });
        return response;
    }
}

const subscriptionService = new SubscriptionService()
export { subscriptionService };
